<template>
  <div class="quick-view__container">
    <s-dialog
      v-if="isAddToCartModalLoaded"
      v-model:visible="status.popupModal"
      v-ada-modal="{ show: status.popupModal, first: adaOpenModal }"
      class="new-goodsdc-dialog quick-view__dialog"
      :class="[
        quickViewConfig.isMiniLayout ? 'quick-view__min-layout' : '',
        quickViewConfig.isMatchingStyles ? 'quick-view__matching-styles__layout' : '',
        quickViewConfig.styleType
          ? `quick-view__${quickViewConfig.styleType}`
          : '',
        quickViewConfig.underPriceStyle
          ? 'quick-view__under-price'
          : '',
        isRomwe ? 'romwe' : ''
      ]"
      type="W996"
      :show-close="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      :close-aria-label="language.SHEIN_KEY_PC_15522"
      @open="openHandle"
      @close="closeHandle"
    >
      <template v-if="footerSwiperData && status.isShowFooterMainImg">
        <div class="quick-view__big-img">
          <img :src="transformImg({ img: footerSwiperData.collectImg })" />
        </div>
      </template>
      <div
        v-else
        ref="viewInfo"
        tabindex="0"
        aria-label="Quick View"
        class="quick-view__info j-quick-view__info"
      >
        <!-- left products -->
        <template v-if="leftProductsData">
          <quick-view-left-products
            :active-id="currentGoodsId"
            :root-status="status"
            :prop-data="leftProductsData"
            :analysis="analysis"
            :quick-view-cb="quickViewCb"
            :quick-view-config="quickViewConfig"
            :site-mall-info="currentGoodsData.siteMallInfo"
            :language="language"
            :is-hit-compliance-mode="isHitComplianceMode"
            @handle-item-click="handleGoodsColorClick"
            @on-slide-change-end="handleSlideChangeEnd"
          />
        </template>

        <!-- siwper S -->
        <quick-view-swiper
          ref="quickViewSwiper"
          :fixed-ratio="fixedRatio"
          :goods-data="currentGoodsData"
          :quick-view-config="quickViewConfig"
          :best-deal-config="bestDealConfig"
          :query-loading="status.queryLoading"
          :root-status="status"
        />
        <!-- siwper E -->
        <!-- 右侧商品信息 S -->
        <div 
          ref="quickViewDes"
          class="quick-view__des"
        >
          <!-- 商品标题/价格 -->
          <quick-view-summary
            ref="quickViewSummaryRef"
            :goods-data="currentGoodsData"
            :main-couponcode="mainCode"
            :is-sold-out="status.isSoldOut"
            :add-bag-form="addBagForm"
            :sale-attr-list="saleAttrList"
            :analysis="analysis"
            :is-show-follow-belt="isShowFollowBelt"
            :quick-view-config="quickViewConfig"
            :fixed-ratio="fixedRatio"
            :estimated-info="estimatedInfo"
            :lowest-price-tips-config="lowestPriceTipsConfig"
            :best-deal-config="bestDealConfig"
            :real-time-ready="realTimeReady"
            :page-list-type="pageListType"
            :ccc-flash-zone-data="currentGoodsData?.cccFlashZoneData"
            :show-shein-club="showSheinClub"
            :compliance-mode="complianceMode"
            :compliance-tips-mode="complianceTipsMode"
            :is-inversion="isInversion"
            :is-inversion-de="isInversionDe"
            :de-discount-info="deDiscountInfo"
            :compliance-mode-de="complianceModeDe"
            :exclusive-promotion-price="exclusivePromotionPrice"
            :new-flash-promotion="newFlashPromotion"
            :promotion-info="promotionInfo"
            :is-hit-compliance-mode="isHitComplianceMode"
            :s3-club-price-info="s3ClubPriceInfo"
            :pspc-price-info="pspcPriceInfo"
            :is-show-promotion-count-down="isShowPromotionCountDown"
            :is-show-count-down-mill-sec="isShowCountDownMillSec"
            :promotion-end-time="promotionEndTime"
          />
          <!-- 商品颜色 -->
          <quick-view-color
            :goods-data="currentGoodsData"
            :language="language"
            :root-status="status"
            :quick-view-cb="quickViewCb"
            :analysis="analysis"
            :quick-view-config="quickViewConfig"
            :colors-comp-data="colorsCompData"
            :fixed-ratio="fixedRatio"
            :show-attr-img-popover="showAttrImgPopover"
            :more-skc-price-infos-map="moreSkcPriceInfosMap"
            :attr-new-icon-ab="attrNewIconAb"
            :is-color-detail-strong="isColorDetailStrong"
            @switch-color="handleGoodsColorClick"
          />
          <!-- 商品尺码 -->
          <quick-view-size
            ref="quickViewSize"
            :goods-data="currentGoodsData"
            :current-size-unit="currentSizeUnit"
            :quick-view-config="quickViewConfig"
            :realtime-bff-info="realtimeBffInfo"
            :language="language"
            :root-status="status"
            :add-bag-form="addBagForm"
            :local-country="cacheLastSelectCountry"
            :sku-no-stock="skuNoStock"
            :quick-view-cb="quickViewCb"
            :extend-qv-data="extendQvData"
            :ccc-config="cccConfig"
            :is-new-size-local="isNewSizeLocal"
            :is-show-size-tips="isShowSizeTips"
            :is-abt-show-quick-ship="isAbtShowQuickShip"
            :show-attr-img-popover="showAttrImgPopover"
            :tsp-show-buy-size-table="tspShowBuySizeTable"
            :config="{
              localSizeList: currentGoodsData.localSizeList,
              showHightSize: isShowHeightSize,
              showTaxation: showTaxation,
              hideOneSize: hideOneSize,
              complianceMode: complianceMode,
              complianceTipsMode: complianceTipsMode,
              isHitComplianceMode: isHitComplianceMode,
              hideRelationPlusSize: quickViewConfig.hideRelationPlusSize,
              hideRelationGoods: quickViewConfig.hideRelationGoods,
              hideSizeGroupeSize: quickViewConfig.hideSizeGroupeSize,
              getSkcEstimatedInfo: skcEstimatedInfo,
              getEstimatedInfo: estimatedInfo,
              qtySelectShow: quantityShow,
              hiddenPartialQuickShip,
              shouldWeakenDefaultSize,
              defaultlocalsize: defaultlocalsize,
              showOnlyOneLocalSize: defaultLocalSizeConfig.showOnlyOneLocalSize,
              swapSizeTabPosition: defaultLocalSizeConfig.swapSizeTabPosition,
              onlyOneLocalSizeNewOn: defaultLocalSizeConfig.onlyOneLocalSizeNewOn,
              isAutoSwitchMall,
              isS3newPrice
            }"
            :find-similar-float-info="findSimilarFloatInfo"
            @mall-click="handleMallClick"
            @change-local-country="handleChangeLocalCountry"
            @handle-click-size-group-item="handleClickSizeGroupItem"
          />
          <!-- 快速加车数量 -->
          <quick-view-quantity
            v-if="quantityShow && !status?.queryLoading"
            :goods-data="currentGoodsData"
            :quantity="addCartQuantity"
            :current-mall="currentMall"
            :language="language"
            :sku-info="skuInfo"
            :sku-code="skuCode"
            :show-tips="isSatisfiedBuyMultiple"
            :promotion-info="promotionInfo"
            :get-estimated-info="estimatedInfo"
            :screen-abt-config="screenAbtConfig"
            :screen-abt-tsp-config="screenAbtTspConfig"
            @change-quantity="changeQuantity"
          />
          <!-- view full details && 积分收集提示 -->
          <quick-view-integral
            :goods-data="currentGoodsData"
            :add-bag-form="addBagForm"
            :analysis="analysis"
            :quick-view-config="quickViewConfig"
            :page-list-type="pageListType"
            :fixed-ratio="fixedRatio"
            :one-click-pay-state="oneClickPayState"
          />
          <!-- 快速加车按钮 -->
          <quick-view-button
            class="quick-view__buttoncomp"
            :active-id="currentGoodsId"
            :init-login-status="initLoginStatus"
            :popup-modal="status.popupModal"
            :main-id="mainGoodsId"
            :active-promotion-id="currentPromotionTypeId"
            :goods-data="currentGoodsData"
            :add-bag-form="addBagForm"
            :wishlist-data="wishlistData"
            :analysis="analysis"
            :quick-view-cb="quickViewCb"
            :quick-view-config="quickViewConfig"
            :sale-attr-list="saleAttrList"
            :default-mall-code="defaultMallCode"
            :common-abt-params="commonAbtParams"
            :show-one-click-pay="showOneClickPay"
            :one-click-pay-state="oneClickPayState"
            :goods-pic-type="goodsPicType"
            :fixed-ratio="fixedRatio"
            :find-similar-float-info="findSimilarFloatInfo"
            :estimated-info="estimatedInfo"
            :page-list-type="pageListType"
            :show-buy-now-reason="showBuyNowReason"
            :promotion-info="promotionInfo"
            :best-deal-config="bestDealConfig"
            :ccc-add-cart-prompt="cccAddCartPrompt"
            :monitor-data="monitorData"
            @handle-close-popups="hide"
            @update-one-click-pay-stage="updateOneClickPayStage"
          />
        </div>
        <!-- 右侧商品信息 E -->
      </div>
      <template
        v-if="footerSwiperData"
        #footer
      >
        <quick-view-footer-swiper
          :active-id="currentGoodsId"
          :root-status="status"
          :prop-data="footerSwiperData"
          :analysis="analysis"
          :quick-view-cb="quickViewCb"
          :quick-view-config="quickViewConfig"
          :site-mall-info="currentGoodsData.siteMallInfo"
          @handle-item-click="handleGoodsColorClick"
        />
      </template>

      <!-- loading -->
      <div
        v-if="status.queryLoading"
        class="quick-view__loading"
      >
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </s-dialog>

    <!-- 购物车满的提示 -->
    <s-dialog
      v-if="isRootTipsLoaded"
      v-model:visible="status.rootTips"
      class="new-goodsdc-dialog"
      :show-close="true"
      :append-to-body="true"
    >
      <template #title>
        <span>
          <template v-if="status.vipHelpTips">
            {{
              language.SHEIN_KEY_PC_17426
            }}
          </template>
        </span>
      </template>

      <template #footer>
        <span v-if="status.notShowFooter"></span>
      </template>

      <div>
        <template v-if="status.goodsOutTips">
          {{ language.SHEIN_KEY_PC_14617 }}
        </template>

        <template v-if="status.cartFullTips">
          {{ language['300407'] }}
        </template>

        <template v-if="status.vipHelpTips">
          <div v-html="language.SHEIN_KEY_PC_17472"></div>
        </template>
      </div>
    </s-dialog>
    <OneClickPay
      :should-auth="oneClickPayAuth"
      :goods-data="currentGoodsData.detail"
      :current-mall-code="currentGoodsData.mallCode"
      :bill-no="oneClickPayState.upperBillNo"
      :stage="oneClickPayState.stage"
      @verify-done="oneClickPayVerifyDone"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { transformImg, template, getLocalStorage } from '@shein/common-function'
import { cloneDeep } from 'lodash'
const { lang, IS_RW, SiteUID } = gbCommonInfo

// gloable variable: cart_module
import QuickViewSwiper from './components/QuickViewSwiper'
import QuickViewSummary from './components/QuickViewSummary'
import QuickViewSize from './components/QuickViewSize_v2.vue'
import QuickViewColor from './components/QuickViewColor_v2'
import QuickViewLeftProducts from './components/QuickViewLeftProducts'
import QuickViewQuantity from './components/QuickViewQuantity'
import QuickViewButton from './components/QuickViewButton'
import QuickViewFooterSwiper from './components/QuickViewFooterSwiper'
import QuickViewIntegral  from './components/QuickViewIntegral'
import OneClickPay from 'public/src/pages/goods_detail_v2/components/OneClickPay'
import { daEventCenter } from 'public/src/services/eventCenter'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { promotionCenter } from 'public/src/pages/common/promotion'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import schttp from 'public/src/services/schttp'
import { getRetentionConfig } from 'public/src/pages/goods_detail_v2/utils/common'
import { abtservice } from 'public/src/services/abt'
import { getFitPercentageRoundNum } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'
import getEstimatedInfo from 'public/src/pages/goods_detail_v2/utils/getEstimatedInfo'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { showBuyNow } from 'public/src/pages/goods_detail_v2/utils/buyNow.js'
import { initMonitor, monitor } from './quick_add_monitor'
// import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

daEventCenter.addSubscriber({ modulecode: '2-8' }) // 快速加车
daEventCenter.addSubscriber({ modulecode: '1-8-6' }) // 商详 - 热门色号

const COMMON_ABT_KEYS = {
  posKeys: [
    'CccGoodsdetail',
  ].join(','),
  newPosKeys: ['UnderPrice', 'goodsPicAb'].join(',')
}

export default {
  name: 'QuickViewPopups',
  components: {
    QuickViewSwiper,
    QuickViewColor,
    QuickViewSize,
    QuickViewQuantity,
    QuickViewLeftProducts,
    QuickViewButton,
    QuickViewFooterSwiper,
    QuickViewIntegral,
    QuickViewSummary,
    OneClickPay
  },
  inheritAttrs: false,
  data() {
    return {
      quickViewButtonWidth: '375px',
      quickViewButtonHeight: '0',
      pageListType: '',
      findSimilarApiTimeStamp: '',
      unSkuWatch: () => {},
      mallUnWatch: () => {},
      // skc_sku_unwatch: () => {},
      findSimilarFloatInfo: {},
      isRomwe: IS_RW,
      originLanguage: {},
      language: {},
      goodsDataCache: {},
      goodsCCCConfigCache: {},
      sizeCache: {},
      // 商品颜色
      // colorCache: {},
      /**
       * quickView的配置，调用者可以传入配置
       */
      quickViewConfig: {},
      // quickView根状态，子组件可能会共享其值，quickView自己独有的状态
      status: {
        popupModal: false,
        queryLoading: false,
        // 提示弹窗
        rootTips: false,
        // 是否展示底部主图大图模式
        isShowFooterMainImg: false,
        // 是否是售罄
        isSoldOut: false,
        // 没有选择销售属性
        notSelectSize: false,
        // 商品不存在
        goodsOutTips: false,
        // 购物车满了
        cartFullTips: false,
        // vip价格解释
        vipHelpTips: false,
        // 是否展示Modal的底部按钮
        notShowFooter: true,
        // 当前商品id改变是否是通过打开一个新的qv
        isFromNewOpen: false,
        // 是否展示Modal底部2个按钮
        towBtn: false,
        // 加车状态
        addBagStatus: 0, // 加车状态 - 0: 加车失败/未点击加车 1: 加车成功
      },
      // 需要处理的回调函数
      quickViewCb: {},
      extendOpenDone: {}, // 扩展打开完成qv之后执行的回调，仅限于qv内部使用
      // 登陆注册框
      // loginModal: {
      //   show: false,
      //   successCb: null
      // },
      // 当前
      currentGoodsId: '',
      mainGoodsId: '',
      mainCode: '',
      currentGoodsData: {
        detail: null
      },
      // 底部swiper的数据
      footerSwiperData: null,
      // 左侧数据
      leftProductsData: null,
      // 对应goodsId的收藏与否集合
      wishlistData: {},
      // 埋点参数配置
      analysis: null,
      // 加车参数
      addBagForm: {
        quantity: 1,
        sizeInfo: null
      },
      // addBagFormCache: {},
      extendQvData: null,
      sizeRuleCache: new Map(),
      showSizeGuide: false,
      currentSizeUnit: 0,
      currentMallConfig: {},
      saleAttrList: {}, // 销售属性
      skuNoStock: {}, // 无库存sku_code
      // sheinClubPromotionInfo: {},
      firstFetch: true, // 是否首次请求数据
      defaultMallCode: '', // 默认mall
      cacheLastSelectCountry: '',
      commonAbtParams: {}, // 用于特例发生于快速加车弹窗的埋点数据
      // 调用方控制的是否需要展示oneClickPay
      showOneClickPay: false,
      // 一键购状态
      oneClickPayState: {
        support: false,
        upperBillNo: '',
        billInfo: null,
        stage: 1
      },
      cccConfig: {
        desc: {},
        tips: '',
        isReady: false
      },
      promotionTagInfos: {},
      showPromotionTagInfo: false,
      promotionTypeId: '',
      isOpenRequest: false,
      showEstimatedPriceTemp: false,
      fixedRatio: null,
      initLoginStatus: false,
      isAddToCartModalLoaded: false,
      isRootTipsLoaded: false,
      monitorData: {
        hit: false,
        scene: ''
      },
      realtimeBffInfo: {},
      realtimeBffInfoCache: {},
      skcEstimatedInfo: null,
      priceReportList: []
    }
  },
  computed: {
    hideOneSize() {
      const { parentCats } = this.currentGoodsData || {}
      // 屏蔽one-size的商城ID
      const shieldOneSizeCatId = [1864, 2032, 2273, 3195, 3634, 3637, 3657]
      const arr = [parentCats]
      for (let i = 0; i < arr.length; i++) {
        const { cat_id, children } = arr[i] || {}
        if (children?.length) arr.push(...children)
        const catId = +cat_id
        if (shieldOneSizeCatId.includes(catId)) {
          return true
        }
      }
      return false
    },
    moreSkcPriceInfosMap() {
      return this.realtimeBffInfo?.saleAttr?.moreSkcPriceInfos?.reduce?.((res, item) => {
        const { goodsId, lowerPriceFlag } = item || {}
        if (goodsId) {
          res[goodsId] = !!+lowerPriceFlag
        }
        return res
      }, {}) || {}
    },
    currentPromotionTypeId() {
      return this.promotionTagInfos?.[this.currentGoodsId]?.typeId
    },
    tspShowBuySizeTable() {
      const { tsp = {} } = this.currentGoodsData || {}
      return !!tsp?.[600029050]
    },
    colorsCompData() {
      return {
        promotionTagInfos: this.promotionTagInfos,
        promotionTypeId: this.promotionTypeId
      }
    },
    isNewSizeLocal() {
      if (this.quickViewConfig?.addSource == 'DetailPage') {
        return true
      }
      return false
    },
    defaultlocalsize() {
      return this.screenAbtConfig?.defaultlocalsize?.p?.defaultlocalsize
    },
    defaultLocalSizeConfig() {
      const  { country_code } = this.currentGoodsData?.localSizeList || {}
      const defaultlocalsize = this.screenAbtConfig?.defaultlocalsize?.param || {}
      const swapSizeTabPosition = defaultlocalsize?.size_tab_sort === 'new' && defaultlocalsize?.defaultlocalsize === 'old'
      const onlyOneLocalSizeNewOn = defaultlocalsize?.onlyonelocalsize === 'new_on'
      const showOnlyOneLocalSize = ['on', 'new_on'].includes(defaultlocalsize?.onlyonelocalsize) && !!country_code
      return {
        swapSizeTabPosition,
        onlyOneLocalSizeNewOn,
        showOnlyOneLocalSize
      }
    },
    isAutoSwitchMall() {
      return this.screenAbtConfig?.goodsdetailmall?.p?.mallautoswitch == 'on'
    },
    localCountry() {
      // 缓存 =》 站点 =》 ip
      let country = window.localStorage.getItem('last_select_country')
      if (country === 'default') return ''
      let defaultlocalsize = this.screenAbtConfig?.defaultlocalsize?.p?.defaultlocalsize
      if (!country && (defaultlocalsize === 'new' || defaultlocalsize === 'default_size_no_default')) return ''
      if (!country) {
        country = (lang && lang.toUpperCase()) || ''
      }
      if (!this.multiLocalSizeRules.get(country)) {
        const countryData = UserInfoManager.getSync({ key: 'Country', actionType: '/quickView/QuickView' })
        const pcCountry = countryData?.countryAbbr || ''

        const countryCorrect = {
          GB: 'UK'
        }
        country = countryCorrect[pcCountry] || pcCountry
      }
      return country
    },
    hasHotGoods() {
      return (
        this.currentGoodsData &&
        this.currentGoodsData.hotGoodSnList &&
        this.currentGoodsData.hotGoodSnList.length &&
        this.status.popupModal
      )
    },
    productRelationID() {
      const { detail = null } = this.currentGoodsData
      return detail && detail.productRelationID
    },
    realTimeGoodsId() {
      const { detail = null } = this.currentGoodsData
      return detail?.goods_id || ''
    },
    multiLocalSizeRules() {
      if(this.currentGoodsId) {
        // const data = this.sizeRuleCache.get(this.currentGoodsId) || {}
        // // 空数据的时候是个数组？
        // if (data['size_rule_list'] && Array.isArray(data['size_rule_list']))
        //   return {}
        // // localCountry
        // return data['size_rule_list'] || {}
        const size_rule_list = this.sizeRuleCache.get(this.currentGoodsId)?.size_rule_list || {}
        return new Map(Object.entries(size_rule_list))
      } else {
        return new Map()
      }
    },
    isSatisfiedBuyMultiple() {
      return this.estimatedInfo?.isSatisfiedBuyMultiple
    },
    quantityShow() {
      if (this.skcEstimatedInfo?.isSatisfiedBuyMultiple) return true
      if(this.quickViewConfig.isExchangeButton) return false
      if(this.quickViewConfig.isShowSelectUpdate) return false
      const { currentGoodsData } = this
      return currentGoodsData?.cccTemplateData?.content?.[0]?.countCell == 1
    },
    addCartQuantity() {
      const { addBagForm } = this
      return addBagForm.quantity || 1
    },
    skuInfo() {
      const { addBagForm } = this
      return addBagForm?.sizeInfo
    },
    skuCode() {
      const { addBagForm } = this
      return addBagForm?.selectedAttrSkuCode
    },
    currentMall() {
      let currentMall = {}
      if (this.skuInfo?.sku_code) {
        // sku维度的mall
        currentMall = this.skuInfo.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
        currentMall.can_sale_days = currentMall.sku_can_sale_days
      } else {
        // skc维度的mall
        currentMall = this.currentGoodsData?.detail?.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
        currentMall.can_sale_days = currentMall.skc_can_sale_days
      }
      return currentMall
    },
    oneClickPayAuth() {
      const { currentGoodsData, showOneClickPay } = this
      return currentGoodsData?.oneClickPayAuth && showOneClickPay
    },
    isShowSizeTips() {
      let showTrueToSizeStrongText = ''
      const { detail = {}, attrSizeList = {}, apolloConfig = {}, commentInfo = {}, parentCats = {} } = this.currentGoodsData
      const { sale_attr_list } = attrSizeList
      const goodsId = detail?.goods_id
      const skc_sale_attr = sale_attr_list?.[goodsId]?.skc_sale_attr || []
      // 有尺码属性
      const hasSizeAttr =  skc_sale_attr.find(_ => _.attr_id == 87)
      const { trueToSizeStrongCatId = [] } = apolloConfig?.sizeTipsConfig || {}
      // 取评论
      const true_size = commentInfo?.percent_overall_fit?.true_size || ''
      const comment_num = commentInfo?.comment_num || 0
      const true_size_num = getFitPercentageRoundNum(true_size)
      if(hasSizeAttr && true_size_num >= 90 && comment_num >= 10 && this.checkCatId([parentCats], trueToSizeStrongCatId)) {
        const lanText = this.language.SHEIN_KEY_PC_28172
        showTrueToSizeStrongText = template('', lanText)
      }
      const defaultData = {
        // 是否命中实验组
        // isHitExperiment: this.abtSupportSizeTips,
        // 是否大码提示
        isLargeSizeTips: this.isLargeSizeTips,
        // 提示文案
        sizeTipsText: this.isLargeSizeTips
          ? this.language.SHEIN_KEY_PC_25123
          : this.language.SHEIN_KEY_PC_25124,
        // 是否展示
        // true to size 强化文案
        showTrueToSizeStrongText,
        true_size_text: true_size,
        showTrueToSizeStrong: !!showTrueToSizeStrongText,
        isShow: false
      }

      // 1. 是否目标品类id
      if (!this.isHitCatId()) {
        return defaultData
      }

      // 2. 是否异常尺寸
      if (!this.isExceptionSize()) {
        return defaultData
      }

      return {
        ...defaultData,
        isShow: true
      }
    },
    // abtSupportSizeTips() {
    //   const { sizeTips } = this.screenAbtConfig
    //   // A 展示提示 B 不展示提示（默认项）
    //   return sizeTips?.param?.SizeTips == 'A'
    // },
    isLargeSizeTips() {
      const { max, large } = this.getSizeTipsData()
      return large >= max
    },
    catIds() {
      const { catIds = [] } =
        this.currentGoodsData?.apolloConfig?.sizeTipsConfig || {}
      return catIds
    },
    isShowHeightSize() {
      const { heightCatIds = [] } =
        this.currentGoodsData?.apolloConfig?.sizeTipsConfig || {}
      return this.checkCatId([this.currentGoodsData?.parentCats], heightCatIds)
    },
    showTaxation() {
      const { showTaxation } = this.currentGoodsData?.apolloConfig?.sizeTipsConfig || {}
      return !!showTaxation
    },
    // 保留商品配置信息
    retentionPositionConfig() {
      const { isSoldOut } = this.status
      const { tsp = {} } = this.currentGoodsData || {}
      const { SameLabel } = this.screenAbtConfig
      const position = this.quickViewConfig?.showBestDealLabel
        ? SameLabel?.param?.SameLabel || ''
        : ''
      const contentText = SameLabel?.param?.labeltype == 'label2' ? this.language.SHEIN_KEY_PC_28750 : this.language.SHEIN_KEY_PC_25737

      return getRetentionConfig({
        position,
        tsp,
        isSoldOut,
        contentText
      })
    },
    isShowFollowBelt () {
      // 快车配置收口是否需要展示跟价腰带
      const { showFollowBeltByOrigin = false } = this.quickViewConfig || {} 
      const FOLLOW_GOODS_TAGID = 3658
      const { isSoldOut } = this.status
      const { tsp = {} } = this.currentGoodsData || {}
      const { FollowLabel: { param: { FollowLabel = 'NO' } = {} } = {} } = this.screenAbtConfig || {}
      const isShowFollowBeltByAbt = FollowLabel === 'banner' // abt展示banner
      const isFollowGoods = !!tsp?.[FOLLOW_GOODS_TAGID] || false // 跟价款商品
      return showFollowBeltByOrigin && isShowFollowBeltByAbt && isFollowGoods && !isSoldOut
    },
    screenAbtConfig() {
      const abts = this.currentGoodsData?.screenAbtConfig || {}

      if (abts && abts.DetailBuynow) { // ABT 兜底暂时按推全分支处理
        abts.DetailBuynow.p.quickprice = abts?.DetailBuynow?.p?.quickprice ?? 'Ceiling'
      }

      return abts
    },
    screenAbtTspConfig() {
      return this.currentGoodsData?.screenAbtTspConfig || {}
    },
    goodsPicType() {
      const { goodsPicAb } = this.screenAbtConfig || {}
      const picType = goodsPicAb?.param?.goodsPicAb || null
      return picType
    },
    isAbtShowQuickShip() {
      const { screenAbtConfig } = this
      return screenAbtConfig?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
    },
    tspConfigCombo() {
      const { tsp, detail = {} } = this.currentGoodsData
      return {
        hitLowestPrice: this.getLowestPrice(tsp, detail)
      }
    },
    lowestPriceTipsConfig() {
      const { screenAbtConfig, language, tspConfigCombo } = this
      const { pricestatues = {} } = screenAbtConfig
      const { lowestprice } = pricestatues?.p || {}
      const { hitLowestPrice = '' } = tspConfigCombo || {}
      if (!hitLowestPrice) return { show: false }
      return {
        show: lowestprice == 'show',
        hitLowestPrice,
        contentText: template(hitLowestPrice, language.SHEIN_KEY_PC_27675),
      }
    },
    /**
     * @returns { Object }
     *  @param { Boolean } show 是否显示
     *  @param { String } text 文案内容
     *  @param { String } type 文案类型 
     *                          [retentionBelt 保留款腰带 / lowestBelt x天最低价腰带 / followBelt 近期低价腰带] 
     *                          [retentionText 保留款文本 / lowestText x天最低价文本 / followText 近期低价文本] 
     */
    bestDealConfig() {
    // 这里聚合了 保留款｜x天最低价｜近期低价  优先级： 「保留款」>「x天最低价」 >「近期降价」
      const { screenAbtConfig = {}, retentionPositionConfig = {}, isShowFollowBelt = false, lowestPriceTipsConfig = {}, language = {} } = this  
      const { isSoldOut } = this.status
      const { pricestatues = {} } = screenAbtConfig
      const { showtype = 'banner' } = pricestatues?.p || {} // TODO pricebottom

      if (isSoldOut) return { show: false }

      let text = '',
          tipsType = '', // 保留款 2｜x天最低价 1｜近期低价 3
          rightText = '',
          type = null
  
      if (retentionPositionConfig.show) {
        text = retentionPositionConfig.contentText
        rightText = retentionPositionConfig.rightText
        tipsType = 2
        type = 'retention'
      } else if (lowestPriceTipsConfig.show) {
        text = lowestPriceTipsConfig.contentText
        tipsType = 1
        type = 'lowest'
      } else if (isShowFollowBelt) {
        text = language.SHEIN_KEY_PC_26629
        tipsType = 3
        type = 'follow'
      } else return { show: false }
  
      if ((showtype == 'banner' || !showtype) && tipsType !== 1) type = type + 'Belt'
      if (showtype == 'pricebottom' || tipsType == 1) type = type + 'Text'
  
  
      return {
        text,
        type,
        tipsType,
        rightText
      }
    },
    mallCode() {
      return this.currentGoodsData.mallCode
    },
    productInfo() {
      return this.currentGoodsData.detail || {}
    },
    productInfoPrice() {
      return this.productInfo?.mall?.[this.mallCode] || {}
    },
    skuPromotionInfo() {
      return this.skuInfo?.mall?.[this.mallCode]?.promotionInfo
    },
    skcPromotionInfo() {
      return this.productInfoPrice.promotionInfo
    },
    promotionInfo() {
      let needShieldTypeIdsOnInversion = [3, 24, 30, 31]
      let result = this.skuPromotionInfo || this.skcPromotionInfo || []
      if (this.isInversion || this.isInversionDe) return result.filter(item => !needShieldTypeIdsOnInversion.includes(Number(item.typeId)))
      return result
    },
    isShowPromotionCountDown() {
      // 1. 命中ABT Salepricetimelimit = show
      // 2. 命中单品直降活动，且非闪购商品
      // 3. 没有到手价
      const isShow = this.screenAbtConfig?.Detailimelimit?.param?.Detailimelimit === 'show'
      // const isShow = true
      const promotionInfo = this.promotionInfo || []
      const ids = [1, 2, 3, 4, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 28, 29, 30, 31]
      const hasValue = this.estimatedInfo?.value // 存在有效到手价
      return !hasValue && isShow && promotionInfo.filter(item => ids.includes(Number(item.typeId))).length > 0
    },
    // 单品直降活动结束时间
    promotionEndTime() {
      const isShowPromotionCountDown = this.isShowPromotionCountDown
      if (!isShowPromotionCountDown) return null
      function isEndTimeIn24(nowStamp, endTimestamp) {
        return (
          endTimestamp - nowStamp > 0 && endTimestamp - nowStamp < 60 * 60 * 24 * 1000
        )
      }
      const promotionInfo = this.promotionInfo || []
      const ids = [1, 2, 3, 4, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 28, 29, 30, 31]
      const promotionList = promotionInfo.filter(item => ids.includes(Number(item.typeId)))
      const nowStamp = new Date().getTime()
      const promotionEndTimeList =
        promotionList?.filter(item => isEndTimeIn24(nowStamp, item.endTimestamp * 1000)).map(item => item.endTimestamp) || []
      const promotionEndTime = promotionEndTimeList.length ? Math.min.apply(null, promotionEndTimeList) : null
      return promotionEndTime
    },
    // 倒计时是否展示毫秒（实际是十分之一秒）
    isShowCountDownMillSec () {
      return this.screenAbtConfig?.Detailimelimit?.param?.Timelimitmillisec === 'show'
    },
    flashType() {
      if (this.isInversion || this.isInversionDe) return null
      const flashGoods = this.promotionInfo && this.promotionInfo.find((item) => Number(item.typeId) === 10)
      return flashGoods?.flashType
    },
    sheinClubPromotionInfo() {
      const {
        promotionInfo,
        productInfoPrice,
        quickViewConfig,
        addBagForm,
        mallCode,
      } = this
      const { sizeInfo = {} } = addBagForm
      const isSheinClub = promotionInfo?.find(({ typeId }) => +typeId === 29)
      if (
        !!!isSheinClub ||
        quickViewConfig.isExchangeButton ||
        quickViewConfig.hidePromotionInfo
      )
        return null
      const {
        showPrice = {},
        vipDiscountPrice = {},
        discountValue = 0,
      } = sizeInfo?.mall?.[mallCode]?.showPrice
        ? sizeInfo?.mall?.[mallCode]
        : productInfoPrice
      return {
        price: showPrice,
        discountValue: ((1 - discountValue) * 100).toFixed(0),
        discount_amount: vipDiscountPrice,
        sku_code: sizeInfo?.sku_code || null,
      }
    },
    estimatedInfo() {
      if (!this.quickViewConfig.showEstimatedPrice && !this.quickViewConfig?.showAbPrice) return null
      let {
        screenAbtConfig,
        detail,
        mallCode,
        apolloConfig,
        isPaidUser
      } = this.currentGoodsData
      let { exclusivePromotionPrice, newFlashPromotion, s3ClubPriceInfo } = this
      let {
        addBagForm,
        flashType,
        sheinClubPromotionInfo,
        promotionInfo,
        showSheinClub,
        complianceMode,
        complianceModeDe,
        skcEstimatedInfo
      } = this
      if (!addBagForm?.sizeInfo && skcEstimatedInfo) return skcEstimatedInfo
      const { sizeTipsConfig = {}, DETAIL_SECOND_CONFIG  } = apolloConfig || {}
      const { estimatedPriceConfig = [] } = sizeTipsConfig
      let showNoSatisfied = this.quickViewConfig?.showNoSatisfied
      if (!this.isMatchCateId) showNoSatisfied = false
      let result =  getEstimatedInfo({
        abtValue: {
          EstimatedPrice: screenAbtConfig?.EstimatedPrice,
          EstimatedShowType: screenAbtConfig?.EstimatedShowType?.p?.EstimatedShowType,
          Estimatedstrategy: screenAbtConfig?.EstimatedNothreShowType?.p?.Estimatedstrategy,
          EstimatedNothreShowType: screenAbtConfig?.EstimatedNothreShowType?.p?.EstimatedNothreShowType,
          Estimatedclubshow: 'New',
          sheinappwordsize: screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize,
          middleast: screenAbtConfig?.middleast?.p?.middleast,
          showaddprice: screenAbtConfig?.EstimatedNothreShowType?.p?.showaddprice,
          shownprice: screenAbtConfig?.EstimatedNothreShowType?.p?.shownprice,
          showncate: screenAbtConfig?.EstimatedNothreShowType?.p?.showncate
        },
        mallCode: mallCode,
        detail: detail,
        isPaidUser,
        skuInfo: addBagForm?.sizeInfo,
        showSheinClub,
        exclusivePromotionPrice,
        s3ClubPriceInfo,
        flashType,
        newFlashPromotion,
        promotionInfo,
        sheinClubPromotionInfo,
        isSoldOut: this.status.isSoldOut,
        isOnlyAbPrice: !this.quickViewConfig?.showEstimatedPrice && this.quickViewConfig?.showAbPrice,
        estimatedPriceConfig,
        SiteUID,
        showEstimatedPrice: !!this.quickViewConfig.showEstimatedPrice,
        showNoSatisfied,
        closeEstimatedAndAbPrice: this.currentGoodsData.closeEstimatedAndAbPrice,
        complianceMode,
        complianceModeDe,
        parentCats: this.currentGoodsData?.parentCats,
        N_ESTIMATED_SITE_CAT: DETAIL_SECOND_CONFIG?.N_ESTIMATED_SITE_CAT,
        tspLabelsTags: this.currentGoodsData?.tspLabels?.tag || ''
      })
      if (result?.isSatisfiedBuyMultiple) {
        if (this.quickViewConfig?.addSource != 'DetailPage') return null
        if (!this.extendOpenDone?.extendForm?.quantity) return null
      }
      return result
    },
    isMatchCateId() {
      return this.currentGoodsData?.addOnItemCate?.result?.isMatchCateId
    },
    showSheinClub() {
      return this.screenAbtConfig?.sheinclubprice?.p === 'exposure_prime_price'
    },
    originCouponList(){
      const newCouponList = this.estimatedInfo?.coupons || []
      const hasValue = this.estimatedInfo?.value // 存在有效到手价
      let getEstimatedInfo = this.estimatedInfo
      if(hasValue && newCouponList.length){
        const optimalCouponList = getEstimatedInfo?.optimalCouponList || []
        optimalCouponList.forEach(coupon => {
          let idx = newCouponList?.findIndex(
            item => item.coupon_code === coupon?.coupon_code
          )
          if (idx > -1) {
            if(optimalCouponList.length > 1){
              if (getEstimatedInfo?.isAbCoexist || (getEstimatedInfo?.isAb && getEstimatedInfo?.isEstimatedstrategyNone)) {
                if (optimalCouponList.length > 2) {
                  newCouponList[idx].is_stacked_best_coupon = true
                } else {
                  newCouponList[idx].is_estimated_best_coupon = true
                }
              } else {
                newCouponList[idx].is_stacked_best_coupon = true
              }
            } else {
              newCouponList[idx].is_estimated_best_coupon = true
            }
          }
        })
      }
      let formatedNewCouponList = newCouponList ? newCouponList.map((item) => {
        return souceFormat(item)
      }) : []

      return formatedNewCouponList
    },
    hasDiscountCouponMatch(){
      let salePrice = 0
      if (this.currentGoodsData.isPaidUser) {
        salePrice = this.sheinClubPromotionInfo?.price?.amount
      } else if (this.currentGoodsData.newFlashPromotion?.price?.amount) {
        salePrice = this.currentGoodsData.newFlashPromotion?.price?.amount
      } else {
        salePrice = this.skuInfo?.mall?.[this.currentGoodsData?.mallCode]?.salePrice?.amount || this.currentGoodsData?.detail?.mall?.[this.currentGoodsData?.mallCode]?.salePrice?.amount
      }
      let couponList = this.originCouponList || []
      let hasMatch = false
      couponList.find(item => {
        if (
          ![5, 6, 9].includes(Number(item.apply_for)) &&
          item.coupon_type_id == 1 &&
          item.coupon_rule?.[0].min?.amount &&
          Number(item.coupon_rule?.[0].min?.amount) <= Number(salePrice)
        ) {
          hasMatch = true
          return true
        }
      })
      return hasMatch
    },
    // 商品免邮
    isProductFreeShipping() {
      let { goodsFreeShippingInfo: { rule_id = 0 } = {} } = this.currentGoodsData || {}
      return rule_id > 0
    },
    // 活动免邮
    isPromotionFreeShipping() {
      let shippingMethods = this.currentGoodsData.shippingDetail?.shippingDetail?.shippingMethods || []
      if (shippingMethods?.length) {
        for (let i of shippingMethods) {
          let { is_support_free_shipping, aging_is_show, has_zero_threshold, full_amount, starting_price, step_price } = i
          if (is_support_free_shipping != 0 && aging_is_show != 0) {
            if (has_zero_threshold == 1 && full_amount?.amount == 0) {
              return true
            }
            if (has_zero_threshold == 0 && starting_price == 0 && step_price == 0) {
              return true
            }
            if (has_zero_threshold == 0 && full_amount?.amount != 0) {
              let estiamtedPrice = this.estimatedInfo?.value?.amount
              let salePrice = 0
              if (this.currentGoodsData.isPaidUser) {
                salePrice = this.sheinClubPromotionInfo?.price?.amount
              } else if (this.currentGoodsData.newFlashPromotion?.price?.amount) {
                salePrice = this.currentGoodsData.newFlashPromotion?.price?.amount
              } else {
                salePrice = this.skuInfo?.mall?.[this.currentGoodsData?.mallCode]?.salePrice?.amount || this.currentGoodsData?.detail?.mall?.[this.currentGoodsData?.mallCode]?.salePrice?.amount
              }
              let price = estiamtedPrice || salePrice
              // 免邮活动有门槛，并且存在满减券，并且商品符合满减，那么不展示免邮
              return !this.hasDiscountCouponMatch && Number(full_amount?.amount) <= Number(price)
            }
          }
        }
      }
      return false
    },
    isCouponFreeShipping() {
      if (this.isProductFreeShipping || this.isPromotionFreeShipping || this.hasDiscountCouponMatch) return false
      return false
    },
    isFreeShippin() {
      let { isProductFreeShipping, isPromotionFreeShipping, isCouponFreeShipping } = this
      return isProductFreeShipping || isPromotionFreeShipping || isCouponFreeShipping
    },
    showBuyNowReason() {
      let {
        estimatedInfo,
        sheinClubPromotionInfo,
        currentGoodsData, 
        skuInfo,
        screenAbtConfig,
        status,
        isFreeShippin,
        oneClickPayState
      } = this

      let salePriceAmount = 
        estimatedInfo?.value?.amount ||
        sheinClubPromotionInfo?.price?.amount ||
        currentGoodsData?.newFlashPromotion?.price?.amount ||
        skuInfo?.price?.salePrice?.amount ||
        currentGoodsData?.detail?.salePrice?.amount
      let param = {
        DetailBuynowAbt: screenAbtConfig?.DetailBuynow?.p,
        isSoldOut: status.isSoldOut || currentGoodsData?.mallStock === 0,
        oneClickPayStateSupport: oneClickPayState.support,
        isCustomization: currentGoodsData?.detail?.customization_flag == 1,
        tsp: currentGoodsData?.tsp,
        isFreeShippin: isFreeShippin,
        getEstimatedInfo: estimatedInfo,
        salePriceAmount,
        lang: lang,
        parentCats: currentGoodsData?.parentCats
      }
      let result = showBuyNow(param)

      return result
    },
    /**
     * 色块尺码显示图片气泡
     */
    showAttrImgPopover() {
      return this.screenAbtConfig?.goodsMainAttPicShow?.param?.goodsMainAttPicShow === 'on'
    },
    // 价格倒挂
    isInversion() {
      if (!this.complianceMode) return false
      const newFlashPromotion = this.currentGoodsData.newFlashPromotion
      if (newFlashPromotion?.price?.amountWithSymbol) {
        if (newFlashPromotion.is_inversion || !newFlashPromotion.suggested_sale_price?.amountWithSymbol) return true
        return false
      }
      let { skuInfo } = this
      let { mallCode, detail } = this.currentGoodsData
      let price = skuInfo?.mall?.[mallCode] || detail?.mall?.[mallCode] || {}
      if (price.isInversion || !price.suggestedSalePrice?.amountWithSymbol) return true
      return false
    },
    complianceMode() {
      return this.currentGoodsData?.apolloConfig?.complianceMode
    },
    complianceModeDe() {
      return this.currentGoodsData?.apolloConfig?.complianceModeDe
    },
    complianceTipsMode() {
      return this.currentGoodsData?.apolloConfig?.complianceTipsMode
    },
    isHitComplianceMode() {
      return this.currentGoodsData?.apolloConfig?.isHitComplianceMode
    },
    deDiscountInfo() {
      let { newFlashPromotion, detail, mallCode, isPaidUser } = this.currentGoodsData
      let { skuInfo, sheinClubPromotionInfo } = this
      let price = skuInfo?.mall?.[mallCode] || detail?.mall?.[mallCode] || {}

      let retailPriceAmount = parseFloat(price?.retailPrice?.amount || 0)
      let mainPriceAmount = parseFloat((isPaidUser && sheinClubPromotionInfo) ? sheinClubPromotionInfo?.price?.amount : newFlashPromotion?.price?.amount || price?.salePrice?.amount) || 0
      let suggestedSalePriceAmount = parseFloat(newFlashPromotion?.suggested_sale_price?.amount || price?.suggestedSalePrice?.amount || 0)

      let retailShow = false
      let suggestedShowDe = false
      let suggestedDiscountShow = false
      if (suggestedSalePriceAmount > 0) {
        if (suggestedSalePriceAmount > mainPriceAmount) {
          suggestedShowDe = true
          suggestedDiscountShow = ((isPaidUser && sheinClubPromotionInfo) ? price.discountValue : (newFlashPromotion?.discountPercent || price?.unit_discount)) > 0 // 30 天最低价没倒挂展示折扣率，倒挂不展示折扣率
          if (retailPriceAmount != mainPriceAmount && retailPriceAmount > suggestedSalePriceAmount) {
            retailShow = true
            suggestedShowDe = true
          }
        } else { // 30 天最低价倒挂
          if (retailPriceAmount != mainPriceAmount && retailPriceAmount != suggestedSalePriceAmount) {
            retailShow = true // 30 天最低价倒挂仍然可以展示原价
            suggestedShowDe = true
          }
        }
      }

      let suggestedDiscountPercentValue = newFlashPromotion?.discountPercent || price?.unit_discount
      let retailDiscountPercentValue = newFlashPromotion?.retail_discount_percent || price?.retailDiscountPercent
      if (isPaidUser && sheinClubPromotionInfo) {
        suggestedDiscountPercentValue = price.discountValue
        retailDiscountPercentValue = price?.retailDiscountValue
      }
      let result = {
        retailPrice: {
          show: retailShow,
          value: price?.retailPrice?.amountWithSymbol
        },
        retailDiscountPercent: {
          show: retailShow,
          value: retailDiscountPercentValue
        },
        retailDiscountPrice: {
          show: retailShow,
          value: newFlashPromotion?.retail_discount_price || price?.retailDiscountPrice,
        },
        suggestedSalePrice: {
          show: suggestedShowDe,
          value: newFlashPromotion?.suggested_sale_price?.amountWithSymbol || price?.suggestedSalePrice?.amountWithSymbol || ''
        },
        suggestedDiscountPercent: {
          show: suggestedDiscountShow,
          value: suggestedDiscountPercentValue
        },
        isInversionDe: !retailShow && !suggestedShowDe
      }
      return result
    },
    isInversionDe() {
      if (this.complianceModeDe) return this.deDiscountInfo?.isInversionDe
      return false
    },
    exclusivePromotionPrice() {
      return !!this.promotionInfo.find(item => item.typeId == 12) ? this.currentGoodsData.originExclusivePromotionPrice : null
    },
    pspcPriceInfo() {
      return this.currentGoodsData.getPrice?.pspcPriceInfo || {}
    },
    s3ClubPriceInfo(){
      const { mallCode, pspcPriceInfo, exclusivePromotionPrice } = this
      if(!pspcPriceInfo?.isS3ClubPromotion || !exclusivePromotionPrice?.amountWithSymbol) return null // 不存在s3会员活动时，没有s3会员价
      const skcMallPrices = pspcPriceInfo?.skcMallPrices || []
      const skcMallItem = skcMallPrices.find(item => item.mall_code == mallCode)
      return skcMallItem || null
    },
    skcNewFlashPromotion() {
      return this.currentGoodsData?.newFlashPromotion
    },
    newFlashPromotion() {
      let result = cloneDeep(this.skcNewFlashPromotion)
      if (!result?.price?.amountWithSymbol) return null
      let skuInfoMallInfo = this.skuInfo?.mall?.[this.mallCode]
      if (skuInfoMallInfo?.showTypeId == 10) {
        result = skuInfoMallInfo
        result.price = skuInfoMallInfo.showPrice
      }
      let discountPercent = result?.discount_percent || result?.discountValue

      return {
        ...result,
        discountPercent
      }
    },
    cccAddCartPrompt() {
      const { cccAddCartPrompt } = this.currentGoodsData || {}
      const items = cccAddCartPrompt?.content?.items ?? []
      return items
    },
    // 隐藏部分 quickship 场景的显示
    hiddenPartialQuickShip() {
      return false
    },
    // 当用户选择本地化尺码，且同时展示默认尺码时，弱化括号内默认尺码展示, 默认不是弱化
    shouldWeakenDefaultSize() {
      return this.screenAbtConfig?.defaultlocalsize?.p?.localstrong === 'on'
    },
    isS3newPrice() {
      return this.screenAbtConfig?.EstimatedPrice?.param?.S3newprice === 'new'
    },
    colorpickAb() {
      return this.screenAbtConfig?.colorpick?.param?.colorpick
    },
    attrNewIconAb() {
      return this.screenAbtTspConfig?.goodssaleattribute?.param?.attrnewicon
    },
    isColorDetailStrong() {
      return this.screenAbtTspConfig?.goodssaleattribute?.param?.color_details === 'on'
    },
  },
  watch: {
    async currentGoodsId(newId, oldId) {
      if (!newId) return
      // 请求售罄推荐商品
      if (typeof window !== 'undefined' && oldId != newId) {
        const findSimilarChange = async () => {
          this.unSkuWatch?.()
          this.mallUnWatch?.()
          // 切换skc的时候有sku存在需要等待sku更新
          if (this.skuCode) {
            await new Promise((resolve) => {
              this.unSkuWatch = this.$watch('skuCode', () => {
                this.unSkuWatch?.()
                resolve()
              })
            })
          }
          this.$nextTick(() => {
            this.updateFindSimilarFloat({ from: 'skcChange' })
          })
          // 监听sku变化
          this.unSkuWatch = this.$watch('skuCode', () => {
            this.$nextTick(() => {
              this.updateFindSimilarFloat({ from: 'skuChange' })
            })
          })
          this.mallUnWatch = this.$watch('currentGoodsData.mallCode', () => {
            this.$nextTick(() => {
              this.updateFindSimilarFloat({ from: 'skuChange' })
            })
          })
        }
        findSimilarChange()
      }
      // 重置状态
      this.resetStatus()
      // 请求数据
      // 1. 先清空当前数据
      this.currentGoodsData = this.$options.data().currentGoodsData
      this.cccConfig = this.$options.data().cccConfig
      this.skcEstimatedInfo = null
      // 2. 再请求赋值
      this.$nextTick(() => {
        this.fetchProductData()
      })
    },
    'status.popupModal'(bol) {
      if (bol) {
        // 1. 获取或者生成一个traceid
        const traceid = gbExposeTraceid('getComponent', {
          componentName: this.analysis.pageType || ''
        })
        // 2. 给当前商品设置一个traceid
        gbExposeTraceid('setProduct', {
          goods_id: this.currentGoodsId,
          traceid: traceid
        })

        requestAnimationFrame(() => {
          daEventCenter.triggerNotice({
            daId: '2-8-5',
            extraData: {
              index:
                (this.analysis && Number(this.analysis.index || 0) + 1) || 1,
              goodsData: {
                ...this.currentGoodsData,
                sellAttr: this.analysis?.sourceTarget?.getAttribute?.(
                  'data-sell-attr'
                ),
                labelCarousels: this.analysis?.sourceTarget?.getAttribute?.('data-label-carousel')
              },
              isFromBirth:
                this.quickViewConfig.birthdayGift &&
                this.quickViewConfig.birthdayGift.hasAuthority4Gifts,
              activity_from: this.oneClickPayState?.upperBillNo
                ? 'one_tap_pay'
                : this.analysis &&
                  this.analysis.sa &&
                  this.analysis.sa.activity_from,
              userLevel: this.quickViewConfig.userLevel || 0,
              content_id: this.analysis?.sa?.content_id || '',
              scene_list: this.analysis?.sa?.scene_list || '',
              abtest: this.analysis?.sa?.abtest || '',
            }
          })
        })
      }
    },
    realTimeGoodsId(val) {
      if (!val) return

      let addBagForm = this.$options.data().addBagForm
      // 1. 如果有需要扩展的加车参数
      if (this.extendOpenDone.extendForm && this.status.isFromNewOpen) {
        addBagForm = {
          ...addBagForm,
          ...this.extendOpenDone.extendForm
        }
      }
      const curStock = this.goodsDataCache?.[this.currentGoodsId]?.mallStock || 0
      const checkQuantity = this.addBagForm.quantity || 1
      this.changeAddBagData({
        ...addBagForm,
        quantity: checkQuantity > curStock ? curStock : checkQuantity
      })
    },
    skuCode(){
      if(!this.realTimeReady){
        return
      }
      this.estimatedPriceReport()
    }
  },
  provide() {
    return {
      changeRootStatus: this.changeRootStatus,
      changeAddBagData: this.changeAddBagData,
      hide: this.hide,
      changeRootData: this.changeRootData
    }
  },
  async mounted() {
    this.language = this.originLanguage = await i18n.loadByQueue('quick_view')
    this.cacheLastSelectCountry = this.localCountry
    // eslint-disable-next-line
    this.commonAbtParams = await abtservice.getUserAbtResult(COMMON_ABT_KEYS)
  },
  methods: {
    /**
     * 提供给全局其他组件调用
     * @param {String|Number} goodsId 必须，商品id
     * @param {Object} extendForm 非必须，按钮提交 需要扩展的数据
     * @param {Object} extendQvData 硬性修改请求之后qv的数据
     * @param {Object} config 配置
     *    @param {Number|String} userLevel 用户等级
     *    @param {Object} collectGoodsMap 用户收藏商品
     *    @param {Boolean} isMiniLayout 默认false，是否是小尺寸的quickView
     *    @param {Boolean} hideThumb 默认false，是否不展示主swiper旁边的缩略图
     *    @param {Boolean} isUpdateButton 默认false，是否是更新按钮
     *    @param {Boolean} isExchangeButton 默认false，是否是换货按钮
     *    @param {Boolean} isNextButton 默认false，是否是点击下一步
     *    @param {Boolean} hideWishlistBtn 默认false，是否不展示收藏按钮
     *    @param {Boolean} hideQty 默认false，不展示加车数量板块
     *    @param {Boolean} hideColorGroup 默认false，隐藏多颜色
     *    @param {Boolean} hideViewFullDetail 默认false，是否不展示查看详情按钮
     *    @param {Object} birthdayGift 默认undefined，不展示生日礼价格
     *    @param {Boolean} hasAuthority4Gifts 是否有领取生日礼资格
     *    @param {Boolean} showSheinClubDiscountValue 付费会员折扣
     *    @param {Boolean} showNewFlashPriceOnSale 显示特殊闪购价格
     *    @param {Boolean} underPriceStyle 显示一口价样式
     *    @param {Boolean} showOneClickPay 是否需要展示一键购
     *    @param {String} oneClickPayBillNo 一键购订单号
     * @param {Object} footerSwiperData 非必须，默认空，底部swiper的数据
     * @param {Object} leftProductsData 非必须，默认空，左侧商品项的数据
     *    @param {Array} product 横滑商品数组
     *    @param {String} collectImg 底部主图
     * @param {Object} callback 回调函数
     *    @param {Function} handleOpenLogin 请求结果为未登录的时候触发的回调
     *    @param {Function} handleAddToCartDone 加车成功之后的回调 
     *    @param {Function} beginHandleAddToCart 触发加车请求前的回调 
     *    @param {Function} exchangeSubmit 换货成功之后的回调
     *    @param {Function} handleAddWishDone 收藏成功之后的回调
     *    @param {Function} handleCancelWishDone 取消收藏之后的回调
     *    @param {Function} handleUpdateDone 点击更新按钮之后的回调
     *    @param {Function} handleOpenQuickViewDone 完成quickview的数据请求打开之后的回调
     *    @param {Function} handleFooterSwiperSlideEnd 底部swiper滑动结束之后处理的回调函数
     *    @param {Function} handleFooterSwiperItemClick 底部swiper item 点击触发回调
     *    @param {Function} handleAfterAddToBoard 收藏之后选择分组，当分组弹窗操作之后成功的回调
     *    @param {Function} handleNextStepClick 击NEXT按钮的回调
     *    @param {Function} handleSizeClick 点击尺码选择
     *    @param {Function} handleColorClick 点击尺码选择
     *    @param {Function} handleDetailResponse 请求详情信息返回成功后回调
     *    @param {Function} handleMallClick 点击Mall选择
     *    @param {Function} handleOneClickPaySuccess 一键购成功回调
     *    @param {Function} handleOneClickPayFail 一键购失败回调
     *    @param {Function} handleOneClickPayComplete 一键购关联加购信息回调
     *    @param {Function} handleHookCheckAddToGroup 判断收藏之后是否展示收藏分组功能，执行next展示原有逻辑
     * @param {Object} language 多语言覆盖
     *    @param {String} QvAddToBag 加车按钮多语言
     *    @param {String} QvSoldOut 售罄多语言
     * @param {Object} analysis 埋点配置
     *    @param {HTMLDocument} sourceTarget HTML dom, 一般为触发点击唤起组件的target，神策埋点需要
     *    @param {String} pageType 页面类型获取traceid
     *    @param {String|Number} index 非必须，默认不传，quickview曝光埋点需要 ： 2-8-5
     *    @param {Object} daIdConfig 声明式埋点id，如果默认的事件id不能满足，自行扩展id传入替换
     *    @param {Object} sa 自有埋点的自定义字段取值
     *    @param {Object} ga ga埋点的自定义字段取值
     *    @param {Object} extendAnalysis 加车成功上报需要的自定义字段
     *  {
     *   sourceTarget,
     *   index
     *   daIdConfig: {
     *      viewFullDetail
     *      selectSize
     *      selectColor
     *    },
     *   sa: {
     *     code: '', 必须，将作为加车成功之后埋点映射关系，具体 product-item.js 中看映射关系
     *     activity_from: '', 自有加车的来源区分
     *   }
     *   ga: {
     *     gaList: '',   电商代码，ga 的list，加车的ga埋点需要，目的是为了保持跟商品的曝光点击一致，如果不传值为 PageGroup
     *     eventCategory: '' ga 埋点的事件 eventCategory，如果不传会是页面名称
     *
     *     moduleName: ''  如果有gaList就不需要，目前只有推荐位，且加车已有的埋点不满足的时候才需要传，如果传，值为: recommend
     *     moduleTitle: ''  如果有gaList就不需要，目前只有推荐位需要，推荐位名称，ga的list埋点会需要,
     *   }
     */
    show({
      goodsId,
      imgRatio,
      mallCode = '',
      lockmall = 0,
      analysis = {},
      language = {},
      extendForm = null,
      footerSwiperData = null,
      leftProductsData = null,
      config = {},
      callback = {},
      extendQvData = null,
      pageListType,
      mainGoodsId = '',
      mainCode = ''
    } = {}) {
      if (!goodsId) return

      // 监控初始化
      this.monitorData = initMonitor({ scene: config.addSource || analysis?.sa?.activity_from || analysis?.sa?.code || 'other' })

      // 推荐类型
      this.pageListType = pageListType
      // 当前主商品的id
      this.mainGoodsId = mainGoodsId
      // 跳转商详时需携带的主券码
      this.mainCode = mainCode
      // 主图比例
      this.fixedRatio = imgRatio
      // 埋点相关
      this.analysis = analysis
      // footer的数据
      this.footerSwiperData = footerSwiperData
      // left 的数据
      this.leftProductsData = leftProductsData
      // 多语言
      this.language = Object.keys(language).length
        ? Object.assign({}, this.originLanguage, language)
        : this.originLanguage
      // 一键购
      this.updateOneClickPayConfig(config)
      // 配置
      this.showEstimatedPriceTemp = config.showEstimatedPrice
      config.showEstimatedPrice = false
      this.quickViewConfig = {
        ...config,
        showAbPrice: true
      }
      const { collectGoodsMap = {}, showPromotionTagInfo } = config
      this.showPromotionTagInfo = !!showPromotionTagInfo
      this.wishlistData = Object.assign(this.wishlistData, collectGoodsMap)
      // 加车数据
      if (extendForm) {
        this.extendOpenDone.extendForm = extendForm
      }
      extendQvData && (this.extendQvData = extendQvData)
      // 回调函数
      this.quickViewCb = callback

      this.firstFetch = true
      this.currentMallConfig = {
        mallCode,
        lockmall
      }
      this.isOpenRequest = true
      this.currentGoodsId = String(goodsId)
      this.status.isFromNewOpen = true
      if (typeof SHEIN_W !== 'undefined') {
        this.initLoginStatus = !!isLogin?.()
      }
    },
    hide() {
      this.status.popupModal = false
    },
    resetGoodsDataCache() {
      this.goodsDataCache = {}
    },
    closeHandle() {
      if (this.showSizeGuide) return // 打开sizeGuide时，会关闭加车弹窗，但阻止一下初始化的逻辑
      if (this.quickViewCb?.closeQuickView) {
        this.quickViewCb.closeQuickView(this.currentGoodsData.detail)
      }
      this.currentMallConfig = {}
      this.currentGoodsId = ''
      // this.currentGoodsData = this.$options.data().currentGoodsData
      // 重置表单
      this.resetData()

      setTimeout(() => {
        this.status.isFromNewOpen = false
      }, 500)

      !this.status.addBagStatus && monitor({ type: 4, params: this.monitorData })
    },
    estimatedPriceReport() {
      // 需要上报染色埋点
      
      const skc_sku = this.currentGoodsId + '_' + (this.skuCode || '')
      console.log('===>quickview.estimatedPriceReport.skc_sku', skc_sku)
      if(this.priceReportList.includes(skc_sku)){
        console.log(this.priceReportList, '===>quickview.estimatedPriceReport.已经上报过一次了', skc_sku)
        return
      }
      this.priceReportList.push(skc_sku)
      console.log('===>quickview.estimatedPriceReport.priceReportList', this.priceReportList)

      const buriedPrice = this.estimatedInfo?.buriedPrice || null
      console.log('===>quickview.estimatedPriceReport.buriedPrice', buriedPrice)
      if(!buriedPrice){
        return
      }
      const param = {
        daId: '1-8-6-266', // 凑单到手价上报染色埋点showaddprice
        extraData: {
          estimated_price: buriedPrice?.price?.usdAmountWithSymbol || '0',
          sku_code: buriedPrice?.skuCode || '',
          location: 1
        }
      }
      if(buriedPrice?.type == 2){  // N件到手价上报染色埋点shownprice
        param.daId = '1-8-6-267'
        param.extraData.estimated_price_num = buriedPrice?.purchasePcs || 'n'
      }
      daEventCenter.triggerNotice(param)
    },
    /**
     * 请求数据
     */
    async fetchProductData() {
      const goods_id = this.currentGoodsId
      if (!goods_id || this.queryLoading) return
      // 获取缓存
      const mallCode = this.currentGoodsData.hasOwnProperty('mallCode')
        ? this.currentGoodsData.mallCode
        : this.currentMallConfig.mallCode
      if (this.goodsDataCache[goods_id]) {
        const code = this.goodsDataCache[goods_id].detail.mall[mallCode]
          ? mallCode
          : this.goodsDataCache[goods_id].originMallCode
        this.goodsDataCache[goods_id].mallCode = code
        this.goodsDataCache[goods_id].mallStock =
          this.goodsDataCache[goods_id].detail.mall?.[code]?.stock || 0
        this.assignCurrentGoodsData(this.goodsDataCache[goods_id])
        this.updateRealBffInfo({ goods_id, mallCode })
        if(!this.isAddToCartModalLoaded){
          this.isAddToCartModalLoaded = true
          await nextTick()
        }
        this.status.popupModal = true
        const cccConfigCatch = this.getCCCConfigCatchByKey(
          `${this.goodsDataCache[goods_id]?.detail?.cat_id || ''}${this
            .goodsDataCache[goods_id]?.detail?.goods_sn || ''}`
        )
        if (cccConfigCatch) {
          this.cccConfig = cccConfigCatch
        }
        this.fetchPromotion()
        // this.styleGelleryCheck()
        // 触发回调
        if (this.quickViewCb) {
          const { handleOpenQuickViewDone } = this.quickViewCb
          this.status.isFromNewOpen &&
            typeof handleOpenQuickViewDone == 'function' &&
            handleOpenQuickViewDone(true)
        }
        this.getSkcEstimatedInfo()
        return
      }
      // 当前商品 收藏标识
      if (!this.wishlistData.hasOwnProperty(goods_id)) {
        this.wishlistData[goods_id] = this.checkUpwishlist(goods_id)
      }

      const { isOpenRequest, quickViewConfig } = this
      const addCartRule = isOpenRequest && quickViewConfig?.addCartRule

      // 请求数据
      this.status.queryLoading = true
      const lockmall = Number(this.currentMallConfig.lockmall || 0)

      Promise.all([
        schttp({
          url: `/api/productInfo/quickView/get`,
          params: {
            goods_id,
            mallCode: mallCode,
            lockmall: lockmall,
            isQuick: 1,
            addCartRule
          },
          timeout: 30000
        }),
        this.updateRealBffInfo({ goods_id, mallCode })
      ])
        .then(async ([res]) => {
          this.status.queryLoading = false
          this.isOpenRequest = false
          const isSuccess = res.code == 0 && res.info
          if (isSuccess) {
            const { goods, isAddCard, addCardParams = {} } = res.info
            if (goods) {
              if(!this.isAddToCartModalLoaded){
                this.isAddToCartModalLoaded = true
                await nextTick()
              }
              this.status.popupModal = true
              const { handleDetailResponse = a => a } = this.quickViewCb
              const goodsData =
                handleDetailResponse(res.info.goods, this) || res.info.goods
              // 1. 缓存当前商品
              this.goodsDataCache[goods_id] = goodsData
              this.goodsDataCache[goods_id].originMallCode = goodsData.mallCode
              // 2. 当前商品信息
              this.assignCurrentGoodsData(goodsData)
              this.fetchCCCDescConfig()
              this.getQuickViewRealTime(goods_id)
              this.fetchPromotion()
              this.getSkcEstimatedInfo()
            }

            if (isAddCard) {
              const addParams = {
                ...addCardParams,
                trace_id: gbExposeTraceid('getProduct', {
                  goods_id
                })
              }
              this.addCartQuick(addParams)
            }

          } else if (res.code == 1405) {
            if(!this.isRootTipsLoaded){
              this.isRootTipsLoaded = true
              await nextTick()
            }
            this.currentGoodsId = ''
            // 商品不存在
            this.status.rootTips = true
            this.status.goodsOutTips = true
            setTimeout(() => {
              this.status.rootTips = false
              this.status.goodsOutTips = false
            }, 2000)
          } else {
            // 兜底
            this.currentGoodsId = ''
          }
          // 4. 触发配置的回调
          const { handleOpenQuickViewDone } = this.quickViewCb
          this.status.isFromNewOpen &&
            typeof handleOpenQuickViewDone == 'function' &&
            handleOpenQuickViewDone(true)
        })
        .catch(() => {
          this.status.queryLoading = false
          this.currentGoodsId = ''
          // 4. 触发配置的回调
          const { handleOpenQuickViewDone } = this.quickViewCb
          this.status.isFromNewOpen &&
            typeof handleOpenQuickViewDone == 'function' &&
            handleOpenQuickViewDone(false)
        })
    },

    async getQuickViewRealTime(goods_id) {
      const { code, info } = await schttp({
        url: '/api/productInfo/quickViewRealTime/query', 
        method: 'POST',
        data: {
          goods_id
        }
      })
      if (code == 0) {
        this.currentGoodsData.primeShipping = info.primeShipping
        this.currentGoodsData.apolloConfig = { ...this.currentGoodsData.apolloConfig, ...info.realTimeApolloConfigs }
        this.wishlistData[goods_id] = info.wishGood
      }
      this.$nextTick(() => {
        this.realTimeReady = true
        setTimeout(() => {
          this.estimatedPriceReport()
        }, 1000)
      })
    },

    async updateRealBffInfo({ goods_id, mallCode } = {}){
      try {
        const cacheKey = goods_id + '_' + mallCode
        if (this.realtimeBffInfoCache?.[cacheKey]) {
          this.realtimeBffInfo = this.realtimeBffInfoCache?.[cacheKey]
          return
        }
        const _useDebug = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo?.NODE_SERVER_ENV !== 'production' : false
        let addressCookie = getLocalStorage('addressCookie') || {}
        let address_cache = getLocalStorage('address_cache') || {}
        let { addressId, countryId, stateId, cityId, districtId } = addressCookie
        let state = address_cache[stateId]
        let city = address_cache[cityId]
        let district = address_cache[districtId]
        let shippingParams = {
          countryId,
          addressId,
          state,
          city,
          district,
          stateId,
          cityId
        }
        const bffInfo = await schttp({
          method: 'GET',
          url: '/product/get_goods_detail_realtime_data',
          params: {
            goods_id,
            mallCode,
            // web不需要接口自动切换mall
            isUserSelectedMallCode: 1,
            ...shippingParams
          },
          headers: _useDebug ? { 'sheindebug': 676 } : {},
          useBffApi: true,
        }).catch(e => {
          console.error(e)
        })
        if(+bffInfo?.code === 0 && bffInfo?.info) {
          const { saleAttr, shipInfo } = bffInfo?.info || {}
          const result = {}
          // TODO 按需缓存, 后续下线
          result.saleAttr = {
            moreSkcPriceInfos: saleAttr?.moreSkcPriceInfos,
          }
          result.shipInfo = shipInfo
          this.realtimeBffInfo = result
          this.realtimeBffInfoCache[cacheKey] = result
        }
      }catch(e) {
        console.error(e)
      }
    },

    // 售罄推荐商品
    async updateFindSimilarFloat(config = {}) {
      const { from } = config
      // 重置状态
      const initFindSimilarFloat = () => {
        this.findSimilarFloatInfo = {
          loading: false,
          show: 0,
          products: [],
          from,
          filterSize: '',
          mode: from === 'pageInit' ? 'modal' : 'module',
          hasMoreProducts: false,
        }
      }
      const { skuInfo, currentGoodsData, status } = this
      const timeStamp = new Date().getTime()
      this.findSimilarApiTimeStamp = timeStamp
      // 当前商品不售
      if (!(status?.isSoldOut || this.currentGoodsData?.mallStock == 0)) {
        initFindSimilarFloat()
        return
      }
      // 售罄sku切换时展示loading
      this.findSimilarFloatInfo.loading = true
      const { detail } = currentGoodsData || {}
      const currentSizeInfo = skuInfo?.sku_sale_attr?.find?.((item) => item?.attr_id == 87) || {}
      const { cat_id, goods_id } = detail
      // eslint-disable-next-line
      const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: ['popupssoldoutsimilar', 'similaritemsnew'] })
      const { similaritemsnew, popupssoldoutsimilar } = abtInfo || {}
      const soldoutsimilarParam = popupssoldoutsimilar?.param?.popupssoldoutsimilar
      if (!soldoutsimilarParam || soldoutsimilarParam === 'none' || !similaritemsnew?.param) {
        initFindSimilarFloat()
        return
      }
      let rule_id = ''
      const matchResult = similaritemsnew?.param?.match?.(/rule_id=([^&]+)/)
      rule_id = matchResult && matchResult.length >= 2 ? (matchResult[1] || '') : ''
      const filterSize = soldoutsimilarParam === 'show_limit' && currentSizeInfo?.attr_value_name || ''
      const data = await schttp({
        url: '/api/recommend/facadeAtom/get', 
        method: 'POST',
        data: {
          limit: 20,
          // req_num: 20,
          pageEnable: 0,
          scene_id: 18,
          pageNum: 1,
          rule_id,
          contextParams: {
            goods_list: [{
              cate_id: cat_id,
              goods_id: goods_id,
            }],
            filter_size: filterSize
          },
          filterSoldOutConfig: {
            on: true, // *开启售罄过滤
            isPatch: true, // 过滤后补全数据
          },
        },
      })
      if (this.findSimilarApiTimeStamp != timeStamp) return
      const { products } = data?.info || {}
      if (!products || products?.length < 10) {
        initFindSimilarFloat()
        return
      }
      this.findSimilarFloatInfo = {
        loading: false,
        show: (this.findSimilarFloatInfo?.show || 0) + 1,
        products,
        from,
        filterSize: filterSize,
        mode: from === 'pageInit' ? 'modal' : 'module',
        hasMoreProducts: products.length === 20,
      }
    },

    /**
     * currentGoodsData
     */
    assignCurrentGoodsData(goodsData) {
      if (this.extendQvData) {
        const sourceData = cloneDeep(goodsData)
        const { salePrice } = this.extendQvData

        // 价格
        if (
          salePrice &&
          sourceData.detail?.mall?.[sourceData.mallCode]?.salePrice
        ) {
          const orSalePrice =
            sourceData.detail.mall[sourceData.mallCode].salePrice
          sourceData.detail.mall[sourceData.mallCode].salePrice = {
            ...orSalePrice,
            ...salePrice
          }
        }

        this.currentGoodsData = sourceData
      } else {
        this.currentGoodsData = goodsData
      }
      if (this.firstFetch) {
        this.firstFetch = false
        this.defaultMallCode = this.currentGoodsData.mallCode // 记录每次打开弹窗时的mallCode
        monitor({ type: 2, params: this.monitorData })
      }
      let sizeUnit = localStorage.getItem('selectedUnit')
      const {
        sizeInfoDes = {},
        detail = {},
        multiLocalSize = {}
      } = this.currentGoodsData
      this.currentSizeUnit = sizeUnit
        ? Number(sizeUnit)
        : Number(sizeInfoDes.sizeUnit)
      if (!this.sizeRuleCache.get(detail.goods_id)) {
        // this.sizeRuleCache[detail.goods_id] = multiLocalSize
        this.sizeRuleCache.set(detail.goods_id, multiLocalSize)
      }
      if (this.showPromotionTagInfo) {
        this.promotionTypeId = this.getPromotionPriority(
          detail?.promotionInfo
        )?.typeId
      }
    },
    fetchPromotion() {
      const promotionInfo =
        this.currentGoodsData.detail?.mall?.[this.currentGoodsData?.mallCode]
          ?.promotionInfo || []
      let exclusivePromotionId, paidVipPromotionId
      for (let i = 0; i < promotionInfo.length; i++) {
        if (promotionInfo[i].typeId == '12') {
          exclusivePromotionId = promotionInfo[i].id
        }
        if (promotionInfo[i].typeId == '29') {
          paidVipPromotionId = promotionInfo[i].id
        }
        if (
          promotionInfo[i].typeId == '10' &&
          [2, 3, 4].includes(promotionInfo[i]?.flashType)
        ) {
          paidVipPromotionId = promotionInfo[i].id
        }
      }
      if (this.showPromotionTagInfo) this.getSpuPromotionInfo(promotionInfo)
      this.getExclusivePromotionPrice(exclusivePromotionId)
      this.getPromotionPrice(paidVipPromotionId)
    },
    /**
     * 获取skc多促销活动中优先级最高的活动
     */
    getPromotionPriority(promotionInfos) {
      if (!promotionInfos?.length) return {}
      let promotionInfo = {},
          promotionInfoIndex = Infinity
      if (this.showPromotionTagInfo) {
        // 直降类促销，数组顺序对应优先级，位置不可换
        const mallPromotionTypeIds = [10, 3, 24],
              // 非直降类促销
              notMallPromotionTypeIds = [1, 15, 9, 14, 25, 28, 13, 20, 2],
              allPromotionTypeId = [
                ...mallPromotionTypeIds,
                ...notMallPromotionTypeIds
              ]
        promotionInfos?.forEach(promotionData => {
          // 特殊闪购不做处理
          if ([2, 3].includes(promotionData?.flashType)) return
          const typeId = +promotionData?.typeId
          const currentIndex = allPromotionTypeId.indexOf(typeId)
          if (currentIndex !== -1 && currentIndex < promotionInfoIndex) {
            promotionInfo = promotionData
            promotionInfoIndex = currentIndex
          }
        })
        if (mallPromotionTypeIds.includes(promotionInfo?.typeId))
          promotionInfo.tagType = 1
        if (notMallPromotionTypeIds.includes(promotionInfo?.typeId))
          promotionInfo.tagType = 2
      }
      return promotionInfo
    },
    async getSpuPromotionInfo() {
      const { mallCode: mall_code, detail } = this.currentGoodsData || {}
      const { goods_id, goods_sn, mall } = detail || {}
      const currentGoodsMall = mall?.[mall_code]
      const spuProductInfo = await schttp({
        url: '/api/productInfo/spuPromotionInfo/get',
        params: {
          goods_id,
          mall_code
        }
      })
      let spuProducts =
        spuProductInfo?.find(item => +item.goods_id === +goods_id)
          ?.spu_products || []
      // 数据结构转换 promotion_info -> promotionInfo
      spuProducts = spuProducts?.map(item => {
        return {
          ...item,
          promotionInfo: item?.promotion_info || []
        }
      })
      const { items } = await promotionCenter.assignItems({
        items: [
          {
            ...currentGoodsMall,
            goods_id,
            goods_sn
          },
          ...spuProducts
        ],
        scence: 'detail',
        caller: 'GoodsDetail'
      })
      const promotionTagInfos = {}
      items?.forEach(item => {
        if (!item.goods_id) return
        const isCurrentGood = +item.goods_id === +goods_id
        const { mall_price } = item || {}
        const isInsurable = isCurrentGood
          ? currentGoodsMall?.isInsurable
          : mall_price?.isInsurable
        const unit_discount = isCurrentGood
          ? currentGoodsMall?.unit_discount
          : mall_price?.unit_discount
        const promotionInfos = item?.promotionModule.rawModule || []
        let promotionInfo = this.getPromotionPriority(promotionInfos)
        // 用户参加保价活动，不显示促销信息
        promotionInfo.showVisible = isInsurable === 0
        // 透传goods_sn
        promotionInfo.goods_sn = item?.goods_sn
        // 当前skc降价比例取促销接口，其他接口取pmsc返回
        promotionInfo.unit_discount = unit_discount
        if (promotionInfo?.id) {
          promotionTagInfos[item.goods_id] = promotionInfo
        }
      })
      this.promotionTagInfos = promotionTagInfos
    },
    async getPromotionPrice(paidVipPromotionId) {
      if (
        paidVipPromotionId &&
        !this.quickViewConfig.isExchangeButton &&
        !this.quickViewConfig.hidePromotionInfo
      ) {
        const { goods_id, goods_sn } = this.currentGoodsData.detail
        let { items } = await promotionCenter.assignItems({
          items: [
            {
              ...this.currentGoodsData.detail?.mall?.[
                this.currentGoodsData?.mallCode
              ],
              goods_id,
              goods_sn
            }
          ],
          isGetVipPrice: true,
          isGetSheinClubPrice: true,
          isGetNewFlashPromotion: true,
          scence: 'detail',
          caller: 'GoodsDetail'
        })

        // this.currentGoodsData['sheinClubPromotionInfo'] =
        //   items[0].sheinClubPromotionInfo
        this.currentGoodsData['newFlashPromotion'] =
          items[0].newFlashPromotion || null
        this.currentGoodsData['promotionData'] =
          items[0]?.promotionModule.rawModule || []
      } else {
        // this.currentGoodsData['sheinClubPromotionInfo'] = { skc: '' }
        this.currentGoodsData['newFlashPromotion'] = null
        this.currentGoodsData['promotionData'] = []
      }
    },
    async getExclusivePromotionPrice(promotionId) {
      const { goods_id, goods_sn } = this.currentGoodsData.detail
      if (promotionId) {
        const result = await itemSer.getAtomicInfo({
          goods: [
            { goods_id, goods_sn, mall_code: this.currentGoodsData.mallCode }
          ],
          fields: { vipDiscountPrices: 1 },
          needAssign: false
        })
        if (result?.[goods_id]?.exclusivePromotionPrice) {
          this.currentGoodsData['originExclusivePromotionPrice'] =
            result[goods_id].exclusivePromotionPrice
        }
      }
      this.quickViewConfig.showEstimatedPrice = this.showEstimatedPriceTemp
    },
    /** CCC配置【新增CCC配置获取, 需求: 尺码选购tips】 */
    async fetchCCCDescConfig() {
      const cateId = this.currentGoodsData?.detail?.cat_id || ''
      const sku = this.currentGoodsData?.detail.goods_sn || ''
      const tsp = JSON.stringify(this.currentGoodsData?.tsp || {})
      const key = `${cateId}${sku}${tsp}`
      const cccConfigCatch = this.getCCCConfigCatchByKey(key)
      if (cccConfigCatch) {
        this.cccConfig = cccConfigCatch
      }
      const res = await schttp({
        url: '/api/productInfo/cccConfig/get',
        params: {
          cateId,
          sku,
          tsp
        }
      })
      const desc = res.desc?.data?.content?.[0]?.content?.props || {}
      const tips =
        res.tips?.data?.content?.[0]?.content?.props?.items?.[0]
          ?.tipsDocument || ''
      const cccConfigData = {
        desc,
        tips,
        isReady: true
      }

      this.cccConfig = cccConfigData
      this.setCCCConfigCatchByKey(cccConfigData)
    },
    getCCCConfigCatchByKey(key) {
      if (!key || this.goodsCCCConfigCache[key]) {
        return
      }

      return this.goodsCCCConfigCache[key]
    },
    setCCCConfigCatchByKey(key, val) {
      if (!key) {
        return
      }

      this.goodsCCCConfigCache[key] = val
    },
    isHitCatId() {
      return this.checkCatId([this.currentGoodsData?.parentCats], this.catIds)
    },
    checkCatId(arr, catIds) {
      // 无catIds配置项时提前返回
      if (!catIds.length) {
        return false
      }

      for (let item of arr) {
        if (catIds.includes(item.cat_id)) {
          return true
        }

        if (item?.children?.length) {
          return this.checkCatId(item?.children, catIds)
        }
      }

      return false
    },
    isExceptionSize() {
      const {
        max,
        min,
        large,
        small,
        quickAddShowTips
      } = this.getSizeTipsData()

      // 快速加车新增tips开关
      if (!quickAddShowTips) {
        return false
      }

      // 存在 146 tips 或 ccc tips 文案时不展示
      if (
        this.cccConfig.tips ||
        !this.cccConfig.isReady
      ) {
        return false
      }

      return (large >= max || small >= max) && Math.abs(large - small) >= min
    },
    getSizeTipsData() {
      const { max: defaultMax, min: defaultMin, quickAddShowTips, offsetSizeConfig = [] } =
        this.currentGoodsData?.apolloConfig?.sizeTipsConfig || {}
      // 从apolloConfig中获取当前站点的最大最小值，若该站点没有配置则使用默认值
      const { max = defaultMax, min = defaultMin } = offsetSizeConfig.find(item => {
        const { SiteUID: ids } = item || {}
        return ids?.includes?.(SiteUID)
      }) || {}
      const { size_tips: sizeTips = {} } =
        this.currentGoodsData?.commentInfo || {}
      const { large, small } = this.getOverallFitNum(sizeTips)

      return {
        max,
        min,
        large,
        small,
        quickAddShowTips
      }
    },
    getOverallFitNum(overallFit) {
      const { large = '', small = '' } = overallFit || {}
      return {
        large: this.delPercentSign(large),
        small: this.delPercentSign(small)
      }
    },
    delPercentSign(str) {
      return Number(str.replace('%', ''))
    },
    /**
     * 检查当前商品是否已经被收藏
     */
    checkUpwishlist(id) {
      if (
        typeof shein_shop_look_vue != 'undefined' &&
        shein_shop_look_vue.added_wish[id]
      ) {
        return 1
      } else if (
        typeof configActData != 'undefined' &&
        configActData.addedWishList &&
        configActData.addedWishList[id]
      ) {
        return 1
      } else {
        return 0
      }
    },
    handleClickSizeGroupItem(item) {
      this.handleGoodsColorClick({
        goods_id: item.goodsId,
      })
    },
    /**
     * 商品颜色的选择
     */
    handleGoodsColorClick(item) {
      this.changeRootStatus({
        isFromNewOpen: false
      })
      if (item.goodsIndex) {
        this.analysis.index = item.goodsIndex
      }
      this.currentGoodsId = item.goods_id

      setTimeout(() => {
        // 切换skc时需要定位到第一张skc图
        const detail_image = this.currentGoodsData?.goods_imgs?.detail_image || []
        const initIndex = detail_image.findIndex(i => !i.is_spu_image)
        if (initIndex > -1) {
          this.$refs['quickViewSwiper'].clickThumbnail(initIndex)
        }
      }, 1000)
    },
    handleMallClick(mallInfo) {
      const { mallCode, mallStock } = mallInfo
      this.currentGoodsData.mallCode = mallCode
      this.currentMallConfig.mallCode = mallCode
      this.currentGoodsData.mallStock = mallStock
      this.fetchPromotion()
      this.updateRealBffInfo({ goods_id: this.currentGoodsId, mallCode: this.mallCode })
    },
    handleChangeLocalCountry(country) {
      this.cacheLastSelectCountry = country
      this.quickViewCb?.handleChangeLocalCountry?.(country)
    },
    async changeRootStatus(params) {
      if(!this.isRootTipsLoaded && params.rootTips){
        this.isRootTipsLoaded = true
        await nextTick()
      }
      if(params.notSelectSize) {
        const offsetTop = document.querySelector('.product-intro__size')?.offsetTop ?? 0
        document.querySelector('.quick-view__dialog .sui-dialog__body').scrollTop = offsetTop
      }
      Object.assign(this.status, params)
    },
    changeAddBagData(params) {
      Object.assign(this.addBagForm, params)
    },
    changeRootData(params) {
      Object.assign(this, params)
    },
    /**
     * ada 首次打开qv聚焦
     */
    adaOpenModal() {
      this.$nextTick(() => {
        this.$refs['viewInfo'] && this.$refs['viewInfo'].focus()
      })
    },
    /**
     * 初始化数据
     */
    resetData() {
      this.addBagForm = this.$options.data().addBagForm
      this.cacheLastSelectCountry = ''
      this.extendOpenDone = {}
      this.extendQvData = null
      this.priceReportList = []
      // this.$nextTick(() => {
      //   this.addBagFormCache = {}
      // })
    },
    /**
     * 重置状态
     */
    resetStatus() {
      Object.assign(this.status, this.$options.data().status, {
        popupModal: this.status.popupModal,
        isFromNewOpen: this.status.isFromNewOpen
      })
    },
    // 改变加车数量
    changeQuantity(quantity) {
      if (isNaN(quantity)) {
        return
      }
      this.addBagForm.quantity = quantity
    },
    openHandle() {
      const daEventExpose = daEventCenter.getExposeInstance()
      daEventExpose?.resetAll?.('QuickViewLeftProducts')
      // 业务组件库抽离后 这里需要注意不要从缓存拿
      const lastSelectCountryInStore = localStorage.getItem('last_select_country')
      this.cacheLastSelectCountry = (lastSelectCountryInStore === 'default' ? '' : lastSelectCountryInStore) || this.localCountry
    },
    // 一键购相关配置更新
    updateOneClickPayConfig(config = {}) {
      const { showOneClickPay = true, oneClickPayBillNo = '' } = config
      this.showOneClickPay = !!showOneClickPay
      const currentStage = this.oneClickPayState.stage
      this.oneClickPayState = {
        support: !!oneClickPayBillNo,
        upperBillNo: oneClickPayBillNo,
        billInfo: {},
        stage: currentStage + 1
      }
      delete config.showOneClickPay
      delete config.oneClickPayBillNo
    },
    // 一键购校验完成回调
    oneClickPayVerifyDone({ hasQualification, billInfo }) {
      const { oneClickPayState } = this
      this.oneClickPayState = {
        ...oneClickPayState,
        support: !!hasQualification,
        billInfo: billInfo || null
      }
    },
    // 更新一键购状态
    updateOneClickPayStage() {
      const { oneClickPayState } = this
      const { stage } = oneClickPayState
      this.oneClickPayState = {
        ...oneClickPayState,
        stage: stage + 1
      }
    },
    async addCartQuick(formData) {
      const { goods_id, quantity } = formData
      const url = `/api/cart/add_mall/create?goods_id=${goods_id}&quantity=${quantity}`
      const params = {
        ...formData,
        // abt_mark: this.goodsPicType, 0725 版本上线
        cart_prime_product_code: window?.cart_prime_product_code || ''
      }
      const data = await schttp({
        method: 'POST',
        url,
        data: params
      })
      if (data.code == 0) {
        data.cart = data.info.cart
        data.info = data.info.info
        this.quickViewCb?.handleAddToCartDone?.(data)
        this.changeRootStatus({ addBagStatus: 1 })
        monitor({ type: 3, params: this.monitorData })
      }
      this.quickViewCb?.handleAddQuickComplete?.({ data })
    },
    handleSlideChangeEnd(goods_id) {
      this.currentGoodsId = String(goods_id)
    },
    getLowestPrice(tsp, currentPrice) {
      // 需求文档: FR-15507
      // 近14天调价: 4018
      // 近30天最低价: 3864 | 近60天最低价: 3865 | 近90天最低价: 3866
      // 近30天最高价: 4015 | 近60天最高价: 4016 | 近90天最高价: 4017
      const d1 = tsp?.['4018'] || ''
      const x1 = +(tsp?.['3864'] || ''),
            z1 = +(tsp?.['4015'] || '')
      const x2 = +(tsp?.['3865'] || ''),
            z2 = +(tsp?.['4016'] || '')
      const x3 = +(tsp?.['3866'] || ''),
            z3 = +(tsp?.['4017'] || '')
      const y = +currentPrice?.salePrice?.usdAmount || ''

      const hitThirtyLowest = !!(y <= x1 && y < z1 && d1 > 1)
      const hitSixtyLowest = !!(y <= x2 && y < z2 && d1 > 1)
      const hitNinetyLowest = !!(y <= x3 && y < z3 && d1 > 1)

      if (hitNinetyLowest) return '90'
      if (hitSixtyLowest) return '60'
      if (hitThirtyLowest) return '30'
      return ''
    },
    getSkcEstimatedInfo() {
      if (!this.quickViewConfig.showEstimatedPrice && !this.quickViewConfig?.showAbPrice) return null
      let {
        screenAbtConfig,
        detail,
        mallCode,
        apolloConfig,
        isPaidUser
      } = this.currentGoodsData
      let { exclusivePromotionPrice, newFlashPromotion, s3ClubPriceInfo } = this
      let {
        flashType,
        sheinClubPromotionInfo,
        promotionInfo,
        showSheinClub,
        complianceMode,
        complianceModeDe,
      } = this
      const { sizeTipsConfig = {}, DETAIL_SECOND_CONFIG = {}  } = apolloConfig || {}
      const { estimatedPriceConfig = [] } = sizeTipsConfig
      let showNoSatisfied = this.quickViewConfig?.showNoSatisfied
      if (this.isMatchCateId) showNoSatisfied = false
      let result =  getEstimatedInfo({
        abtValue: {
          EstimatedPrice: screenAbtConfig?.EstimatedPrice,
          EstimatedShowType: screenAbtConfig?.EstimatedShowType?.p?.EstimatedShowType,
          Estimatedstrategy: screenAbtConfig?.EstimatedNothreShowType?.p?.Estimatedstrategy,
          EstimatedNothreShowType: screenAbtConfig?.EstimatedNothreShowType?.p?.EstimatedNothreShowType,
          Estimatedclubshow: 'New',
          sheinappwordsize: screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize,
          middleast: screenAbtConfig?.middleast?.p?.middleast,
          showaddprice: screenAbtConfig?.EstimatedNothreShowType?.p?.showaddprice,
          shownprice: screenAbtConfig?.EstimatedNothreShowType?.p?.shownprice,
          showncate: screenAbtConfig?.EstimatedNothreShowType?.p?.showncate
        },
        mallCode: mallCode,
        detail: detail,
        isPaidUser,
        // skuInfo: addBagForm?.sizeInfo,
        skuInfo: null,
        showSheinClub,
        exclusivePromotionPrice,
        s3ClubPriceInfo,
        flashType,
        newFlashPromotion,
        promotionInfo,
        sheinClubPromotionInfo,
        isSoldOut: this.status.isSoldOut,
        isOnlyAbPrice: !this.quickViewConfig?.showEstimatedPrice && this.quickViewConfig?.showAbPrice,
        estimatedPriceConfig,
        SiteUID,
        showEstimatedPrice: !!this.quickViewConfig.showEstimatedPrice,
        showNoSatisfied,
        closeEstimatedAndAbPrice: this.currentGoodsData.closeEstimatedAndAbPrice,
        complianceMode,
        complianceModeDe,
        parentCats: this.currentGoodsData?.parentCats,
        N_ESTIMATED_SITE_CAT: DETAIL_SECOND_CONFIG?.N_ESTIMATED_SITE_CAT,
        tspLabelsTags: this.currentGoodsData?.tspLabels?.tag || ''
      })
      if (result?.isSatisfiedBuyMultiple) {
        if (this.quickViewConfig?.addSource != 'DetailPage') return null
        if (!this.extendOpenDone?.extendForm?.quantity) return null
      }
      this.skcEstimatedInfo = result
    },
    async setQuickViewButtonWidth() {
      await this.$nextTick()
      const width = this.$refs['quickViewDes']?.offsetWidth
      width && (this.quickViewButtonWidth = width + 'px')   
    },
    async getQuickViewButtonHeight() {
      await this.$nextTick()
      const height = this.$refs['quickViewButton']?.$el?.offsetHeight
      height && (this.quickViewButtonHeight = height + 16 + 'px')
    },
    transformImg
  }
}
</script>

<style lang="less">
.common-hovertips {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  z-index: 9; /* stylelint-disable-line declaration-property-value-blacklist */
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 5px fade(#000, 8%);
  border-radius: 2px;
  color: #222;
}
.quick-view {
  // 暂时修复bug，提前一步上线，组件库动画修改完成后删掉这些代码
  &__dialog.sui-animation__dialog-enter-from {
    opacity: 0;
    transform: translate3d(
      0,
      -80px,
      999999px
    ) !important; /* stylelint-disable-line declaration-no-important */
  }
  &__dialog.sui-animation__dialog-leave-to {
    opacity: 0;
    transform: translate3d(
      0,
      -80px,
      999999px
    ) !important; /* stylelint-disable-line declaration-no-important */
  }
  &__dialog.sui-animation__dialog-enter-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    animation: none;
  }
  &__dialog.sui-animation__dialog-enter-active.sui-dialog:not(.sui-animation__dialog_W480) {
    animation: none;
  }
  &__dialog.sui-animation__dialog-enter.sui-dialog:not(.sui-animation__dialog_W480) {
    animation: none;
  }
  &__dialog.sui-animation__dialog-leave-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    animation: none;
  }
  &__dialog.sui-animation__dialog-leave-active.sui-dialog:not(.sui-animation__dialog_W480) {
    animation: none;
  }
  &__dialog.sui-animation__dialog-leave.sui-dialog:not(.sui-animation__dialog_W480) {
    animation: none;
  }
  // 修复 end

  &__dialog.quick-view__under-price {
    .quick-view__des {
      .goods-btn__add {
        background-color: #fa6338;
        border: none;
        color: #fff;
      }
    }
    &.romwe {
      .quick-view__des {
        .goods-btn__add {
          background-color: @sui_color_main;
        }
      }
    }
  }

  &__container {
    position: relative;
    z-index: @zindex-modal-background + 1;
  }
  &__info {
    display: flex;
    position: relative;
    color: #222;
  }
  // 小尺寸的quickview
  &__min-layout {
    .modal-content {
      padding: 25px;
    }
    .quick-view__gallecyWrap {
      width: 322px;
      padding-right: 20px;
    }
    .quick-view__des {
      padding-left: 68px;
    }
  }
  &__matching-styles__layout {
    .modal-content {
      padding: 25px;
    }
    .quick-view__gallecyWrap {
      width: 322px;
      padding-right: 20px;
    }
  }

  &__big-img {
    height: 420px;
    text-align: center;
    img {
      height: 100%;
      max-width: 100%;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: @zindex-swiper-arrow;

    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #222;
      border-radius: 50%;
      margin: 0 2px;
      vertical-align: middle;
      animation: ball-pulse 1s ease infinite;
      &:nth-child(1) {
        animation-delay: -0.2s;
      }
      &:nth-child(2) {
        animation-delay: -0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0s;
      }
      /* rw:begin */
      border-radius: 0%;
      background-color: #ff696e;
      animation: line-scale 1s ease infinite;
    }
  }

  /* swiper
  ---------------------------------------------------------*/
  &__gallecyWrap {
    width: 54.5%;
    font-size: 0;
    padding-right: 48px;
    padding-left: 60px;
    &.not-padding-left {
      padding-left: 0;
    }
  }

  &__thumbs {
    // display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    overflow: hidden;
    // position: relative;
  }
  &__thumbs-wrap {
    width: 72px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    vertical-align: top;
    // &::-webkit-scrollbar {
    //   width: 8px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 5px;
    //   background-color: rgba(224,224,224,1);
    // }
    // &::-webkit-scrollbar-track {
    //   border-radius: 5px;
    //   background-color: rgba(247,248,250,1);
    // }
  }
  &__thumbs-item {
    width: 50px;
    // height: 66px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      border: 1px solid #222;
      padding: 2px;
      img {
        width: 44px;
        // height: 60px;
      }
      /* rw:begin */
      border: 1px solid rgba(255, 105, 110, 1);
    }
    &.active {
      border: 1px solid #222;
      padding: 2px;
      img {
        width: 44px;
        // height: 60px;
      }
      /* rw:begin */
      border: 1px solid rgba(255, 105, 110, 1);
    }
    img {
      width: 50px;
      // height: 66px;
    }
  }

  &__main {
    // display: inline-block;
    vertical-align: top;
    position: relative;
    // width: 100%;
    overflow: hidden;
    &:hover {
      .quick-view__main-arrow {
        opacity: 1;
        transform: translateY(-50%);
      }
    }
    img {
      width: 100%;
    }
    .s-swiper-slide{
      width: 100% !important
    }
  }
  &__main-arrow {
    position: absolute;
    // font-size: 16px;
    opacity: 0;
    // transition: opacity .3s, transform .3s;
    cursor: pointer;
    top: 50%;
    z-index: @zindex-swiper-arrow + 1;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: rgba(255, 255, 255, 0.94);
    border-radius: 50%;
    box-shadow: 0 2px 5px fade(#000, 8%);
    .arrow-icon {
      transform: rotate(0deg) /* rtl:rotate(180deg) */;
    }
  }

  &__main-pre {
    left: 10px;
    transform: translate(20%, -50%);
  }
  &__main-next {
    right: 10px;
    transform: translate(-20%, -50%);
  }
  &__main-bullets {
    position: absolute;
    width: 100%;
    height: 8px;
    text-align: center;
    z-index: @zindex-swiper-arrow + 1;
    &.swiper-pagination-bullets {
      bottom: 10px;
    }
    .swiper-pagination-bullet{
      display: inline-block;
      width: 8px;
      height: 8px;
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      margin: 0 3px;
      &.swiper-pagination-bullet-active {
        background: rgba(34, 34, 34, 0.8);
        border: 1px solid rgba(255, 255, 255, 0.8);
      }
    }
  }
  &__main-split {
    &.swiper-pagination-bullets {
      bottom: 40px;
    }
  }
  &__bullet-item {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 3px;
    &.active {
      background: rgba(34, 34, 34, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.8);
    }
  }

  &__des {
    // width: 41%;
    // padding-bottom: 102px;
    flex: 1;
    .txt-l();
    max-width: 45%;
    /* goodsName
    --------------------------------------------------*/
    .goods-name {
      &__title {
        margin-bottom: 8px;
        color: #222;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        font-weight: normal;
        .clamp(1);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        &.no-link {
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }
      }

      &__summary {
        margin-bottom: 8px;
        .goods-stock {
          margin-right: 10px;
        }
        .goods-sku {
          font-size: 12px;
          line-height: 14px;
          color: #767676;
        }
      }
    }
    /* goods Reviews
    --------------------------------------------------*/
    .goods-reviews {
      display: inline-flex;
      margin-bottom: 12px;
      height: 16px;
      .common-rate {
        margin-right: 5px;
        display: inline-block;
      }
      &__text {
        color: #999;
      }
    }
    //评论star
    .common-rate {
      &__star {
        font-size: 0;
      }
      &__item {
        display: inline-block;
        color: @sui_color_honor;
        font-size: inherit;
        font-size: 16px;
        & + .common-rate__item {
          margin-left: 3px;
        }
      }
    }

    /* goodsSpecial
     ----------------------------------------------*/
    .goods-special {
      margin-top: 15px;
      width: 100%;
      padding: 15px;
      background-color: @sui_color_promo_bg;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 700;
      color: @sui_color_promo;
      border-radius: 2px;
      &__price {
        display: inline-flex;
        align-items: center;
        font-size: 24px;
        font-weight: 900;
        font-family: "Arial Black";
        .discount {
          display: block;
          margin-left: 5px;
          background-color: @sui_color_promo;
          color: @sui_color_white;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          padding: 0 8px;
          height: 18px;
          text-align: center;
        }
      }
      &__type {
        font-size: 12px;
        font-weight: 700;
        color: @sui_color_promo;
        &:hover,
        &:focus,
        &:visited,
        &:active {
          color: @sui_color_promo;
          text-decoration: none;
        }
        .icon {
          color: #222222;
        }
      }
    }

    /* goodsColor
    ----------------------------------------------------------------*/
    .goods-color {
      &__container {
        margin-bottom: 15px;
      }
      &__title {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 18px;
        color: #222;
        .active-name {
          color: #999;
          padding-left: 10px;
        }
      }
      &__choose {
        font-size: 0;
      }
      &__item {
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 3px;
        width: 36px;
        height: 36px;
        line-height: 28px;
        border: 1px solid transparent;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        font-size: 0;
        cursor: pointer;
        &:hover {
          border: 1px solid #222;
          /* rw:begin */
          border: 1px solid rgba(255, 105, 110, 1);
        }
        .color-inner {
          position: relative;
          width: 28px;
          height: 28px;
          &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            content: "";
            width: 28px;
            height: 28px;
            border: 1px solid fade(#000, 8%);
            border-radius: 50%;
            background: transparent;
          }
        }
        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
        &.active {
          .color-inner {
            &:after {
              border: 2px solid fade(#000, 8%);
              /* rw:begin */
              border: 2px solid transparent;
            }
          }
          &::before {
            position: absolute;
            left: -1px;
            right: 0;
            top: -1px;
            bottom: 0;
            display: block;
            content: "";
            width: 36px;
            height: 36px;
            border: 2px solid #222;
            border-radius: 50%;
            background: transparent;
            /* rw:begin */
            border: 2px solid rgba(255, 105, 110, 1);
          }
          &::after {
            position: absolute;
            left: 2px;
            top: 2px;
            display: block;
            content: "";
            width: 30px;
            height: 30px;
            border: 2px solid #fff;
            border-radius: 50%;
            background: transparent;
          }
        }
      }
      &__item-hot {
        position: absolute;
        top: -5px;
        right: -12px;
        width: 26px;
        height: 15px;
        font-size: 12px;
        color: #ff411c;
        border: 1px solid rgba(255, 255, 255, 0.86);
        border-radius: 2px;
        z-index: @zindex-hack;
        text-align: center;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #ffece8;
          border-radius: 1px;
        }
        .hot {
          position: absolute;
          top: -2px;
          left: -3px;
          width: 125%;
          height: 125%;
          display: inline-block;
          line-height: 1.5;
          transform: scale(0.8);
          transform-origin: center;
        }
      }
    }

    /* goodsSize
    ----------------------------------------------------------------*/
    .goods-size {
      &__flex {
        display: flex;
      }
      &__title {
        margin-bottom: 15px;
        span {
          font-size: 16px;
          line-height: 18px;
          color: #222;
          &.country {
            text-transform: lowercase;
          }
        }
        // i, a {
        //   color: #666;
        //   font-size: 12px;
        // }
        // a {
        //   text-decoration: underline;
        //   text-transform: capitalize;
        //   &:hover {
        //     color: #000;
        //   }
        // }
      }
      &__size-popover-text {
        padding-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #222;
      }
      &__size-bra-info {
        color: #797979;
        span {
          color: #222;
        }
      }
      &__popover-icon {
        position: absolute;
        bottom: -6px;
        background: #fff;
        z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
        content: "";
        width: 12px;
        height: 12px;
        border-right: 1px solid #f2f2f2 /*rtl:ignore*/;
        border-bottom: 1px solid #f2f2f2;
        transform: rotate(45deg);
      }
      &__item {
        position: relative;
        margin-right: 12px /*rtl:ignore*/;
        margin-bottom: 12px;
        padding: 0 12px;
        min-width: 66px;
        height: 32px;
        line-height: 30px;
        border: 1px solid #e5e5e5;
        border-radius: 16px;
        font-size: 0;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        .inner {
          font-size: 14px;
          display: inline-block;

          span {
            display: inline-block;
          }
        }
        &:hover {
          border: 1px solid #222;
          /* rw:begin */
          border: 1px solid transparent;
          &::before {
            /* rw:begin */
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            border: 2px solid #ff696e;
            border-radius: 16px;
            background: transparent;
          }
        }
      }
      &__item-local {
        text-transform: none;
      }

      &__item-place {
        cursor: auto;
        margin-bottom: 0;
        &:hover {
          border-color: transparent;
        }
      }
      &__item-box {
        display: inline-block;
        // margin-right: 12px;
        p {
          text-align: center;
          color: #9b9b9b;
        }
      }
      &__item-active {
        border: 1px solid transparent;
        .inner {
          font-weight: 700;
        }
        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0 /*rtl:ignore*/;
          right: 0 /*rtl:ignore*/;
          margin: auto;
          content: "";
          width: 100%;
          height: 100%;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border: 2px solid #222;
          box-sizing: content-box;
          border-radius: 16px;
          background: transparent;
          /* rw:begin */
          border: 2px solid #ff696e;
        }
        &:hover {
          border: 1px solid transparent;
        }
        /* rw:begin */
        color: #ff696e;
      }
      &__item-outstock {
        color: #999;
        border: 1px dashed #e5e5e5;
        cursor: not-allowed;
        &:hover {
          border: 1px dashed #e5e5e5;
          /* rw:begin */
          border: 1px solid transparent;
        }
      }
      &__size-popover {
        cursor: default;
        padding: 15px;
        font-size: 12px;
        min-width: 320px;
        line-height: 1.33;
        .txt-l();

        // 临时兼容性处理，产品要求鼠标从按钮滑动到 popover 过程中 popover 不消失，最合理的做法是整体更改组件，但是时间不够
        // wiki.pageId=187793798
        &::before {
          background: rgba(255, 255, 255, 0);
          bottom: -10px;
          content: "";
          height: 10px;
          left: 0 /*rtl:ignore*/;
          position: absolute;
          width: 100%;
        }
      }
      &__size-popover-title {
        margin-bottom: 5px;
        font-weight: 700;
      }
      &__size-popover-list {
        li {
          display: inline-block;
          word-break: break-word;
          margin-right: 10px;
        }
        span {
          color: #999;
        }
      }
      &__size-popover-titleL {
        color: #222;
        display: inline-block;
      }
      &__size-popover-listL {
        color: #797979;
        display: inline-block;
      }
      &__size-popover-stock {
        margin-top: 5px;
        color: #faad14;
        /* rw:begin */
        color: #ff9999;
      }
      &__size-help {
        padding: 2px 0 12px;
      }
      &__size-guide {
        display: inline-block;
        margin-right: 15px;
        color: @sui_color_link;
        font-size: 12px;
        cursor: pointer;
        i {
          font-size: 10px;
          margin-right: 5px;
          display: inline-block;
        }
        &:hover {
          text-decoration: underline;
        }
        /* rw:begin */
        color: @sui_color_link;
      }
      &__flashsale-tips {
        font-size: 12px;
        padding: 3px 0 10px;
        color: rgb(153, 153, 153);
      }

      &__size-tips {
        padding: 3px 0 10px;
        font-size: 12px;
        color: #faad14;
        /* rw:begin */
        color: #ff9999;
      }

      &__tip {
        display: none;
        color: #faad14;
        // height: 36px;
        // line-height: 36px;
        padding: 0 6px;
        &.active {
          display: inline-block;
        }
        /* rw:begin */
        color: #ff9999;
      }

      &__choose {
        margin-bottom: 5px;
      }

      // 尺码描述
      &__des {
        display: inline;
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
        p {
          min-height: 20px;
          line-height: 20px;
          padding: 0 8px;
          background-color: #f5f5f5;
        }
      }
    }
    /* goodsQuantity
    ----------------------------------------------------------------*/
    .goods-quantity {
      &__title {
        display: inline-block;
        margin-right: 15px;
        font-weight: bold;
        line-height: 30px;
        vertical-align: top;
      }
      &__qty {
        margin-bottom: 20px;
      }
      .c-opt-qty {
        display: inline-block;
      }
      .goods-sock__tips {
        font-size: 12px;
        span {
          color: #d53333;
          font-weight: 700;
        }
      }

      .goods-collect {
        font-size: 12px;
        color: #d53333;
        margin-bottom: 20px;
        &__title {
          color: #222;
        }
        p {
          display: inline-block;
        }
      }
    }

    /* goodsButton
    ----------------------------------------------------------------*/
    .goods-btn {
      &__container {
        // position: relative;
        // margin-top: 13px;
        .soldout-warning {
          font-size: 14px;
          color: #222;
          margin-bottom: 15px;
          // margin-top: 15px;
          a {
            font-weight: 700;
            color: #222;
            text-decoration: underline;
          }
        }
        .mall-tip {
          color: @sui_color_unusual;
        }
        .view-detail {
          display: inline-block;
          color: @sui_color_link;
          margin-top: 15px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .view-detail-new {
          color: @sui_color_link;
          // margin-top: 15px;
          cursor: pointer;
          display: flex;
          text-decoration: none;
          padding-bottom: 16px;
          &__more{
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: rgba(45, 104, 168, 0.10);
            margin-left: 4px;
          }
          &__more-icon{
            transform: rotate(0deg) /* rtl:rotate(180deg) */;
          }
        }
      }
      &__group {
        display: flex;
        flex-wrap: wrap;
        /* rw:begin */
        font-family: "Adieu", "Arial", "Helvetica", sans-serif;
      }
      &__add {
        flex: 1 0 51%;
        overflow: hidden;
        // width: 90%;
        height: 54px;
        line-height: 54px;
        font-weight: 700;
        font-size: 18px;
        background-color: rgba(34, 34, 34, 1);
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        transition: background 0.2s, border-color 0.2s, color 0.2s;
        vertical-align: top;
        outline: none;
        border: none;
        /* rw:begin */
        font-family: "Adieu";
        background-color: @color_brand;
        // background-color: @sui_color_main;
        border-radius: 4px;
        &:hover {
          background-color: fade(@color_brand, 80%);
        }
        &[disabled] {
          cursor: not-allowed;
          background-color: fade(@color_brand, 60%);
          color: rgba(255, 255, 255, 0.8);
        }
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        // 加车按钮根加车收藏统一水平线的时候
        &.goods-btn__horizontal {
          // width: 75%;
          margin-right: 32px;
        }
      }
      &__add-loading {
        &.wishlist {
          span {
            background-color: #222;
          }
        }
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #fff;
          border-radius: 50%;
          margin: 0 2px;
          vertical-align: middle;
          animation: ball-pulse 1s ease infinite;
          &:nth-child(1) {
            animation-delay: -0.2s;
          }
          &:nth-child(2) {
            animation-delay: -0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0s;
          }
        }
      }

      &__like {
        position: relative;
        flex: 1 0 51%;
        height: 54px;
        line-height: 54px;
        font-size: 16px;
        font-weight: 700;
        padding: 0;
        text-align: center;
        border: 1px solid #222;
        background-color: #fff;
        color: #222;
        outline: 0;
        cursor: pointer;
        .goods-btn__like-icon {
          &.main-color { 
            color: @sui_color_main; 
          }
          &.anim {
            &::before {
              animation: like-anim 0.8s cubic-bezier(0.17, 0.89, 0.32, 1.49)
                both;
              /* rw:begin */
              color: @color_brand;
            }
          }
          /* rw:begin */
          font-size: 22px;
          &:hover {
            transform: scale(1.1);
          }
          &::before {
            display: inline-block;
          }
        }
        &.goods-btn__horizontal {
          flex: 0 0 54px;
          // width: 44px;
          border-radius: 50%;
          border-color: #e5e5e5;
          /* rw:begin */
          border-radius: 4px;
        }
      }

      &__like-group {
        .flexbox();
        .align-center();
        position: absolute;
        width: auto;
        height: 44px;
        right: 0;
        top: 0;
        animation: qv-add-board-keyframes 500ms forwards ease;
        padding: 0 10px;
        background-color: #fff;
        white-space: nowrap;
        border: 1px solid #f2f2f2;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        font-size: 13px;
        z-index: @zindex-hack;
        &:hover {
          background-color: #e5e5e5;
        }

        i {
          font-size: 28px;
          font-weight: normal;
          vertical-align: middle;
        }
      }

      &__err {
        span {
          color: #d53333;
        }
      }
      &__collect {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 12px;
        line-height: normal;
        color: #999;
        // margin-top: 15px;
        margin-bottom: 12px;
        span {
          color: @sui_color_highlight;
        }
        .num {
          color: #ff8d8f;
          /* rw:begin */
          color: @sui_color_highlight;
        }
      }
    }
  }
  &__buttoncomp{
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -0.5%, #FFF 12.5%);
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 20px;
      position: absolute;
      left: 0;
      top: -20px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00), #FFF);
    }
  }

  &__buttons{
      /* goodsButton, 新的置底布局
    ----------------------------------------------------------------*/
    position: absolute;
    right: 60px;
    bottom: 48px;
    z-index: 2;
    margin-top: 13px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -0.5%, #FFF 12.5%);
    .soldout-warning {
      font-size: 14px;
      color: #222;
      margin-bottom: 15px;
      margin-top: 15px;
      a {
        font-weight: 700;
        color: #222;
        text-decoration: underline;
      }
    }
    .mall-tip {
      color: @sui_color_unusual;
    }
    .view-detail {
      display: inline-block;
      color: @sui_color_link;
      // margin-top: 15px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .view-detail-new {
      color: @sui_color_link;
      // margin-top: 15px;
      cursor: pointer;
      display: flex;
      text-decoration: none;
      &__more{
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(45, 104, 168, 0.10);
        margin-left: 4px;
      }
      &__more-icon{
        transform: rotate(0deg) /* rtl:rotate(180deg) */;
      }
    }
    .goods-btn {
      &__group {
        display: flex;
        flex-wrap: wrap;
        /* rw:begin */
        font-family: "Adieu", "Arial", "Helvetica", sans-serif;
      }
      &__add {
        flex: 1 0 51%;
        overflow: hidden;
        // width: 90%;
        height: 54px;
        line-height: 54px;
        font-weight: 700;
        font-size: 18px;
        background-color: rgba(34, 34, 34, 1);
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        transition: background 0.2s, border-color 0.2s, color 0.2s;
        vertical-align: top;
        outline: none;
        border: none;
        /* rw:begin */
        font-family: "Adieu";
        background-color: @color_brand;
        // background-color: @sui_color_main;
        border-radius: 4px;
        &:hover {
          background-color: fade(@color_brand, 80%);
        }
        &[disabled] {
          cursor: not-allowed;
          background-color: fade(@color_brand, 60%);
          color: rgba(255, 255, 255, 0.8);
        }
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        // 加车按钮根加车收藏统一水平线的时候
        &.goods-btn__horizontal {
          // width: 75%;
          margin-right: 32px;
        }
      }
      &__add-loading {
        &.wishlist {
          span {
            background-color: #222;
          }
        }
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #fff;
          border-radius: 50%;
          margin: 0 2px;
          vertical-align: middle;
          animation: ball-pulse 1s ease infinite;
          &:nth-child(1) {
            animation-delay: -0.2s;
          }
          &:nth-child(2) {
            animation-delay: -0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0s;
          }
        }
      }

      &__like {
        position: relative;
        flex: 1 0 51%;
        height: 54px;
        line-height: 54px;
        font-size: 16px;
        font-weight: 700;
        padding: 0;
        text-align: center;
        border: 1px solid #222;
        background-color: #fff;
        color: #222;
        outline: 0;
        cursor: pointer;
        .goods-btn__like-icon {
          &.main-color { 
            color: @sui_color_main; 
          }
          &.anim {
            &::before {
              animation: like-anim 0.8s cubic-bezier(0.17, 0.89, 0.32, 1.49)
                both;
              /* rw:begin */
              color: @color_brand;
            }
          }
          /* rw:begin */
          font-size: 22px;
          &:hover {
            transform: scale(1.1);
          }
          &::before {
            display: inline-block;
          }
        }
        &.goods-btn__horizontal {
          flex: 0 0 54px;
          // width: 44px;
          border-radius: 50%;
          border-color: #e5e5e5;
          /* rw:begin */
          border-radius: 4px;
        }
      }

      &__like-group {
        .flexbox();
        .align-center();
        position: absolute;
        width: auto;
        height: 44px;
        right: 0;
        top: 0;
        animation: qv-add-board-keyframes 500ms forwards ease;
        padding: 0 10px;
        background-color: #fff;
        white-space: nowrap;
        border: 1px solid #f2f2f2;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        font-size: 13px;
        z-index: @zindex-hack;
        &:hover {
          background-color: #e5e5e5;
        }

        i {
          font-size: 28px;
          font-weight: normal;
          vertical-align: middle;
        }
      }

      &__err {
        span {
          color: #d53333;
        }
      }
      &__collect {
        font-size: 12px;
        color: #999;
        margin-top: 15px;
        span {
          color: @sui_color_highlight;
        }
        .num {
          color: #ff8d8f;
          /* rw:begin */
          color: @sui_color_highlight;
        }
      }
    }
  }
  /** footer swiper
  ----------------------------------------------*/
  &__footer {
    display: flex;
    font-size: 0;
    .txt-l();
    height: 160px;
  }
  &__footer-img {
    flex: 0 0 138px;
    padding-right: 16px;
    height: 100%;
    img {
      width: 100%;
      height: 138px;
      object-fit: cover;
    }
  }
  &__footer-swiper {
    flex: 1;
    overflow: hidden;
    height: 100%;
    position: relative;
    .item-img {
      width: 76px;
      height: 100px;
    }
  }
  &__footer-slider.swiper-slide {
    width: 76px;
    height: 138px;
  }
  &__footer-slider.s-swiper-slide {
    width: 76px;
    height: 138px;
  }
  &__footer-slider {
    margin-right: 12px;
    .inner {
      margin: 0 0 8px;
    }
    &.active {
      .inner {
        padding: 2px;
        border: 1px solid #222;
        img {
          width: 70px;
          height: 94px;
        }
        /* rw:begin */
        border: 1px solid rgba(255, 105, 110, 1);
      }
    }
    p {
      color: #222;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 2px;
      &.sale {
        color: @sui_color_discount;
      }
    }
    del {
      color: #999;
      font-size: 12px;
    }
    .club {
      margin-top: 3px;
    }
  }
  &__footer-next,
  &__footer-prev {
    font-size: 16px;
    margin-top: 38px;
  }
  &__footer-next {
    margin-left: 16px;
  }
  &__footer-prev {
    margin-right: 16px;
  }
  /** left ----------------------------------------------*/

  &__left {
    position: sticky;
    margin-right: 24px;
    top: 0;
    left: 0;
    width: 183px;
    // height: calc(80vh - 96px);
    height: calc(614px - 96px);
    overflow: hidden;
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: #e5e5e5;
    }
  }

  &__left-main {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    .img-wrapper {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.square {
        width: 100%;
        height: 160px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &__left-list {
    position: absolute;
    // height: calc(80vh - 272px);
    height: calc(614px - 272px);
    top: 176px;
    left: 0;
    overflow-y: scroll;
    &.not-series-img {
      top: 0;
      height: calc(614px - 272px + 176px);
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    &-item {
      /* stylelint-disable-line selector-nested-pattern */
      display: inline-flex;
      align-items: center;
      padding: 8px 8px;
      width: 160px;
      height: 79px;
      margin-bottom: 12px;
      color: #222222;
      border: 1px solid #e5e5e5;
      cursor: pointer;
      &.active {
        border: 2px solid #222;
        /* rw:begin */
        border: 2px solid rgba(255, 105, 110, 1);
      }
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .item-left {
      position: relative;
      flex-shrink: 0;
      width: 47px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;

      .sold-out {
        position: absolute;
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        word-break: break-word;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .item-right {
      margin-left: 8px;
      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        width: 90px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &__price {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        &.sale {
          color: @sui_color_discount;
        }
        del {
          font-weight: normal;
          color: #999;
        }
      }
      &__club {
        margin-top: 3px;
      }
    }
  }
  &__left-list-item {
    display: inline-flex;
    align-items: center;
    padding: 8px 6px;
    width: 160px;
    height: 79px;
    margin-bottom: 12px;
    color: #222222;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    &.active {
      border: 2px solid #222;
      /* rw:begin */
      border: 2px solid rgba(255, 105, 110, 1);
    }
  }

  /** 新样式 */
  &__style-type-1 {
    .sui-dialog__wrapper {
      height: 614px;
      max-height: 614px;
    }
    .quick-view__des {
      padding-left: 0;
    }
    .view-detail {
      width: 80%;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      color: @sui_color_gray_dark2 !important; /* stylelint-disable-line declaration-no-important */
    }
    .quick-view__main {
      position: sticky !important; /* stylelint-disable-line declaration-no-important */
      top: 0;
      right: 0;
    }
    .goods-btn__add {
      &.find-similar {
        border: 1px solid @sui_color_gray_dark1;
        color: @sui_color_gray_dark1;
        background: @sui_color_white;
      }
    }
    .goods-btn__container {
      position: sticky;
      padding-top: 12px;
      bottom: -1px;
      left: 0;
      background: @sui_color_white;
      z-index: @zindex-hack;
      &::before {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #e5e5e5;
      }
    }
  }
}

// ipad
// @media (max-width: 850px) {
//   .quick-view__des .goods-btn__add.goods-btn__horizontal {
//     width: 72%;
//   }
// }

@media only screen
and (min-device-width : 568px)
and (max-device-width : 1024px){
  .quick-view__dialog {
    .sui-dialog__wrapper {
      max-width: 100vw !important; /* stylelint-disable-line declaration-no-important */
    }

    .sui-dialog__body {
      padding: 0 20px !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

@keyframes like-anim {
  0% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(0.32);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes qv-add-board-keyframes {
  0% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: -60px;
  }
}
.new-goodsdc-dialog {
  .sui-dialog__body {
    font-size: 12px !important; /* stylelint-disable-line declaration-no-important */
  }
  .opt-title-qty {
    color: #222222;
  }
}
</style>
